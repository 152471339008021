import axios from 'axios';
import qs from 'qs';
import { formatDateSelectors } from '../helpers/date';

const SET_HOSTED_SESSIONS = 'hosted/SET_HOSTED_SESSIONS';

const REPORTING_HOSTED_ENDPOINT = 'reporting/hosted';

const state = { hostedSessions: {} };

const actions = {
  async GET_HOSTED_SESSIONS({ commit }, data) {
    if (data.numberInputsPrefixes) delete data.numberInputsPrefixes;
    const params = formatDateSelectors(data);
        try {
      const response = await axios.get(REPORTING_HOSTED_ENDPOINT, {
        params,
        paramsSerializer: params => qs.stringify(params, { allowDots: true })
      });
      if (response.data.success) {
        commit(SET_HOSTED_SESSIONS, response.data);
        return response.data;
      }
      throw new Error(response.data.message);
    } catch (e) {
      const errorMessage =
        e.response?.data?.message ||
        'Error while getting the Hosted Sessions. Try again later.';
      throw new Error(errorMessage);
    }
  }
};

const getters = {
  getHostedSessions: state => state.hostedSessions
};

const mutations = {
  [SET_HOSTED_SESSIONS](state, data) {
    state.hostedSessions = data;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
