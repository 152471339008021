import { render, staticRenderFns } from "./BaseCustomInput.vue?vue&type=template&id=7d87dac6&scoped=true"
import script from "./BaseCustomInput.vue?vue&type=script&lang=js"
export * from "./BaseCustomInput.vue?vue&type=script&lang=js"
import style0 from "./BaseCustomInput.vue?vue&type=style&index=0&id=7d87dac6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../backoffice/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d87dac6",
  null
  
)

export default component.exports