import axios from 'axios';

const PAYOUTS_ENDPOINT = '/backoffice/payouts';
const PAYOUT_ENDPOINT = `${PAYOUTS_ENDPOINT}/:payoutId`;
const ACK_PAYOUT_ENDPOINT = `${PAYOUT_ENDPOINT}/ack`;
const HOLD_PAYOUT_ENDPOINT = `${PAYOUT_ENDPOINT}/hold`;

// actions
const actions = {
  ACK_PAYOUT(context, params) {
    const data = { status: params.status };
    return new Promise((resolve, reject) => {
      axios
        .put(ACK_PAYOUT_ENDPOINT.replace(':payoutId', params.id), data)
        .then((results) => resolve(results))
        .catch(error => reject(error));
    });
  },
  HOLD_PAYOUT(context, params) {
    const data = { status: params.status };
    return new Promise((resolve, reject) => {
      axios
        .put(HOLD_PAYOUT_ENDPOINT.replace(':payoutId', params.id), data)
        .then((results) => resolve(results))
        .catch(error => reject(error));
    });
  },
  ADD_MANUAL_PAYOUT(context, { subMerchantId, processor, beneficiary, type, amount, createLedgerEntries, description }) {
    return new Promise((resolve, reject) => {
      axios
        .post(PAYOUTS_ENDPOINT, { subMerchantId, processor, beneficiary, type, amount, createLedgerEntries, description })
        .then(response => resolve(response.data))
        .catch(error =>
          reject(
            error.response && error.response.data
              ? error.response.data.message
              : null
          )
        );
    });
  },
  // EDIT_MANUAL_PAYOUT(
  //   context,
  //   { payoutId, subMerchantId, beneficiary, type, amount, createLedgerEntries, description }
  // ) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .put(PAYOUT_ENDPOINT.replace(':payoutId', payoutId), {
  //         subMerchantId,
  //         beneficiary,
  //         type,
  //         amount,
  //         createLedgerEntries,
  //         description
  //       })
  //       .then(response => resolve(response.data))
  //       .catch(error =>
  //         reject(
  //           error.response && error.response.data
  //             ? error.response.data.message
  //             : null
  //         )
  //       );
  //   });
  // },
  DELETE_MANUAL_PAYOUT(context, payoutId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(PAYOUT_ENDPOINT.replace(':payoutId', payoutId))
        .then(response => resolve(response.data))
        .catch(() => {
          reject('Error while deleting the manual payout. Try again later.');
        });
    });
  }
};

export default { actions };
