import axios from 'axios';
import qs from 'qs';

const CUSTOMERS_ENDPOINT = 'backoffice/customers';
const SINGLE_CUSTOMER_ENDPOINT = 'backoffice/customers/:id';
const CUSTOMER_CREDIT_CARDS_ENDPOINT = `${SINGLE_CUSTOMER_ENDPOINT}/creditcards`;
const CUSTOMER_BANK_ACCOUNT_ENDPOINT = `${SINGLE_CUSTOMER_ENDPOINT}/bankaccounts`;

const CUSTOMERS_REPORTING_ENDPOINT = 'reporting/customers';

const SET_CUSTOMERS = 'customers/SET_CUSTOMERS';
const SET_CUSTOMERS_VIRTUAL_TERMINAL = 'customers/SET_CUSTOMERS_VIRTUAL_TERMINAL';

// initial state
const state = { customers: {}, customersVirtualTerminal: {} };

// actions
const actions = {
  async ADD_CUSTOMER(context, data) {
    try {
      const response = await axios.post(CUSTOMERS_ENDPOINT, data);
      return response.data;
    } catch (error) {
      throw new Error('Error while creating the customer. Try again later.');
    }
  },
  async EDIT_CUSTOMER(context, data) {
    try {
      const response = await axios.put(
        SINGLE_CUSTOMER_ENDPOINT.replace(':id', data._id),
        data
      );
      return response.data;
    } catch (error) {
      throw new Error('Error while editing the customer. Try again later.');
    }
  },
  async GET_CUSTOMERS({ commit }, requestData) {
    requestData = requestData || {};
    if (
      requestData.expirationYear ||
      requestData.expirationMonth ||
      requestData.truncatedCardNumber ||
      requestData.cardHolderName ||
      requestData.token
    ) {
      requestData.creditCards = {
        expirationMonth: requestData.expirationMonth || null,
        expirationYear: requestData.expirationYear || null,
        truncatedCardNumber: requestData.truncatedCardNumber || null,
        cardHolderName: requestData.cardHolderName || null,
        token: requestData.token ? `"${requestData.token}"` : null
      };
      delete requestData.expirationMonth;
      delete requestData.expirationYear;
      delete requestData.truncatedCardNumber;
      delete requestData.cardHolderName;
      delete requestData.token;
    }
    try {
      const response = await axios.get(CUSTOMERS_REPORTING_ENDPOINT, {
        params: requestData,
        paramsSerializer: params => qs.stringify(params, { allowDots: true }),
        cancelPreviousRequests: true
      });
      const customersData = [];
      response.data.data.forEach((c, i) => {
        customersData[i] = { ...c };
        if (!(Array.isArray(c.creditCards) && c.creditCards.length > 0)) {
          return;
        }
        const defaultCreditCardIndex = c.creditCards.findIndex(cc => cc.isDefault);
        customersData[i].defaultCreditCard =
          defaultCreditCardIndex > -1
            ? c.creditCards[defaultCreditCardIndex]
            : c.creditCards[0];
        customersData[i].expirationMonth =
          customersData[i].defaultCreditCard?.expirationMonth || null;
        customersData[i].expirationYear =
          customersData[i].defaultCreditCard?.expirationYear || null;
        customersData[i].truncatedCardNumber =
          customersData[i].defaultCreditCard?.truncatedCardNumber || null;
        customersData[i].cardHolderName =
          customersData[i].defaultCreditCard?.cardHolderName || null;
      });
      commit(SET_CUSTOMERS, { count: response.data.count, data: customersData });
      return { count: response.data.count, data: customersData };
    } catch (error) {
      if (axios.isCancel(error)) return;
      else
        throw new Error(`Failed to fetch customers: ${error.message || 'Unknown error'}`);
    }
  },
  async DOWNLOAD_CUSTOMERS(context, data) {
    if (
      data.expirationYear ||
      data.expirationMonth ||
      data.truncatedCardNumber ||
      data.cardHolderName ||
      data.token
    ) {
      data.creditCards = {
        expirationMonth: data.expirationMonth,
        expirationYear: data.expirationYear,
        truncatedCardNumber: data.truncatedCardNumber,
        cardHolderName: data.cardHolderName,
        token: data.token ? `"${data.token}"` : null
      };
      delete data.expirationMonth;
      delete data.expirationYear;
      delete data.truncatedCardNumber;
      delete data.cardHolderName;
      delete data.token;
    }
    try {
      const response = await axios.get(CUSTOMERS_REPORTING_ENDPOINT, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/csv'
        },
        params: { ...data, export: 'csv' },
        paramsSerializer: params => qs.stringify(params, { allowDots: true })
      });
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'octet/stream' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'customers.csv');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw error;
    }
  },
  async ADD_CUSTOMER_TO_LIST({ commit, state }, customer) {
    const customers = JSON.parse(JSON.stringify(state.customers));
    if (!customers.data) {
      return;
    }
    const elementIndex = customers.data.findIndex(c => c._id === customer._id);

    const customerToAdd = {
      ...customer,
      expirationMonth: customer.creditCards[0]
        ? customer.creditCards[0].expirationMonth
        : '',
      expirationYear: customer.creditCards[0]
        ? customer.creditCards[0].expirationYear
        : '',
      truncatedCardNumber: customer.creditCards[0]
        ? customer.creditCards[0].truncatedCardNumber
        : '',
      cardHolderName: customer.creditCards[0]
        ? customer.creditCards[0].cardHolderName
        : '',
      defaultCreditCard: customer.creditCards[0] ? customer.creditCards[0] : ''
    };
    if (elementIndex === -1) {
      customers.data.unshift(customerToAdd);
      customers.count++;
    } else customers.data[elementIndex] = customerToAdd;
    commit(SET_CUSTOMERS, customers);
  },
  async ADD_CREDIT_CARD(context, { customerId, creditCard }) {
    try {
      const response = await axios.post(
        CUSTOMER_CREDIT_CARDS_ENDPOINT.replace(':id', customerId),
        creditCard
      );
      return response.data;
    } catch (error) {
      throw new Error('ADD_CREDIT_CARD');
    }
  },
  async ADD_BANK_ACCOUNT(context, { customerId, bankAccount, subMerchantId }) {
    try {
      const params = { bankAccount };
      if (subMerchantId) {
        params.subMerchantId = subMerchantId;
      }
      const response = await axios.post(
        CUSTOMER_BANK_ACCOUNT_ENDPOINT.replace(':id', customerId),
        params
      );
      return response.data;
    } catch (error) {
      throw new Error('ADD_BANK_ACCOUNT');
    }
  },
  async DELETE_BANK_ACCOUNT(context, { customerId, accountNumber }) {
    try {
      const response = await axios.delete(
        CUSTOMER_BANK_ACCOUNT_ENDPOINT.replace(':id', customerId),
        {
          data: { accountNumber }
        }
      );
      return response.data;
    } catch (error) {
      throw new Error('DELETE_BANK_ACCOUNT');
    }
  },
  async ADD_CREDIT_CARD_TO_LIST({ commit, state }, { customerId, creditCard }) {
    const customers = JSON.parse(JSON.stringify(state.customers));
    const elementIndex = customers.data.findIndex(c => c._id === customerId);
    const customer = customers.data[elementIndex];
    if (customer.creditCards && Array.isArray(customer.creditCards))
      customer.creditCards.push(creditCard);
    else customer.creditCards = [creditCard];
    commit(SET_CUSTOMERS, customers);
  },
  async DELETE_CREDIT_CARD(context, { customerId, token }) {
    try {
      const response = await axios.delete(
        CUSTOMER_CREDIT_CARDS_ENDPOINT.replace(':id', customerId),
        {
          data: { token }
        }
      );
      return response.data;
    } catch (error) {
      throw new Error('DELETE_CREDIT_CARD');
    }
  },
  async DELETE_CREDIT_CARD_FROM_LIST({ commit, state }, { customerId, token }) {
    const customers = JSON.parse(JSON.stringify(state.customers));
    const customerIndex = customers.data.findIndex(c => c._id === customerId);
    const creditCardIndex = customers.data[customerIndex].creditCards.findIndex(
      cc => cc.token === token
    );
    if (creditCardIndex > -1) {
      customers.data[customerIndex].creditCards.splice(creditCardIndex, 1);
      commit(SET_CUSTOMERS, customers);
    }
  },
  async SET_CREDIT_CARD_AS_DEFAULT(context, { customerId, token }) {
    try {
      const response = await axios.put(
        CUSTOMER_CREDIT_CARDS_ENDPOINT.replace(':id', customerId),
        {
          token
        }
      );
      return response.data;
    } catch (error) {
      throw new Error('SET_CREDIT_CARD_AS_DEFAULT');
    }
  },
  async SET_CREDIT_CARD_AS_DEFAULT_ON_LIST({ commit, state }, { customerId, token }) {
    const customers = JSON.parse(JSON.stringify(state.customers));
    const customerIndex = customers.data.findIndex(c => c._id === customerId);
    const customer = customers.data[customerIndex];
    const creditCardIndex = customer.creditCards.findIndex(cc => cc.token === token);
    const oldDefaultCreditCardIndex = customer.creditCards.findIndex(cc => cc.isDefault);
    if (!(creditCardIndex > -1)) {
      return;
    }
    customer.creditCards[creditCardIndex].isDefault = true;
    if (oldDefaultCreditCardIndex > -1)
      customer.creditCards[oldDefaultCreditCardIndex].isDefault = false;
    commit(SET_CUSTOMERS, customers);
  },
  async DELETE_CUSTOMER(context, customerId) {
    try {
      const response = await axios.delete(
        SINGLE_CUSTOMER_ENDPOINT.replace(':id', customerId)
      );
      return response.data;
    } catch (error) {
      throw new Error('DELETE_CUSTOMER');
    }
  },
  async DELETE_CUSTOMER_FROM_LIST({ commit, state }, customerId) {
    const customers = JSON.parse(JSON.stringify(state.customers));
    const customerIndex = customers.data.findIndex(c => c._id === customerId);
    if (customerIndex > -1) {
      customers.data.splice(customerIndex, 1);
      commit(SET_CUSTOMERS, customers);
    }
  },
  async GET_CUSTOMERS_VIRTUAL_TERMINAL({ commit }, requestData) {
    try {
      const response = await axios.get(CUSTOMERS_REPORTING_ENDPOINT, {
        params: requestData
      });
      const customersData = [];
      response.data.data.forEach((c, i) => {
        customersData[i] = { ...c };
        if (!(Array.isArray(c.creditCards) && c.creditCards.length > 0)) {
          return;
        }
        const defaultCreditCardIndex = c.creditCards.findIndex(cc => cc.isDefault);
        customersData[i].defaultCreditCard =
          defaultCreditCardIndex > -1
            ? c.creditCards[defaultCreditCardIndex]
            : c.creditCards[0];
        customersData[i].expirationMonth =
          customersData[i].defaultCreditCard.expirationMonth;
        customersData[i].expirationYear =
          customersData[i].defaultCreditCard.expirationYear;
        customersData[i].truncatedCardNumber =
          customersData[i].defaultCreditCard.truncatedCardNumber;
        customersData[i].cardHolderName =
          customersData[i].defaultCreditCard.cardHolderName;
      });
      commit(SET_CUSTOMERS_VIRTUAL_TERMINAL, {
        count: response.data.count,
        data: customersData
      });
    } catch (error) {
      throw error;
    }
  },
  CLEAR_CUSTOMER({ commit }) {
    commit(SET_CUSTOMERS_VIRTUAL_TERMINAL, {});
  },
  CLEAR_CUSTOMERS({ commit }) {
    commit(SET_CUSTOMERS, {});
  }
};

const getters = {
  getCustomers: state => state.customers,
  getCustomersVirtualTerminal: state => state.customersVirtualTerminal
};

const mutations = {
  [SET_CUSTOMERS](state, data) {
    state.customers = data;
  },
  [SET_CUSTOMERS_VIRTUAL_TERMINAL](state, data) {
    state.customersVirtualTerminal = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
