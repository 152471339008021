import qs from 'qs';
import axios from 'axios';

// Endpoints
const REFERRALS_ENDPOINT = 'backoffice/capital/referrals';

const SET_REFERRALS = 'capital/SET_REFERRALS';

// initial state
const state = { pnReferrals: {} };

// actions
const actions = {
  GET_REFERRALS({ commit }, data) {
    let params = data;
    return new Promise((resolve, reject) => {
      axios
        .get(REFERRALS_ENDPOINT, { params })
        .then(response => {
          commit(SET_REFERRALS, { data: response.data.data });
          resolve(response.data);
        })
        .catch(() =>
          reject('Error while getting the Referrals. Try again later.')
        );
    });
  }
};

const getters = {
  capitalReferrals: state => state.pnReferrals
};

const mutations = {
  [SET_REFERRALS](state, data) {
    state.pnReferrals = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
