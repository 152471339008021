function initialState() {
    return {
        visible: false,
        keepVisible: false,
        containerName: '', //the name of the container that the context menu is attached to, to differentiate between multiple context menus on the same page
        items: [
            { label: '', icon: '' },
        ],
        selectorElement: undefined,
        callbackName: '',
        selectedItem: { value: {}, index: 0 },
    };
}

const state = initialState();

const actions = {
    GENERATE_CONTEXT_MENU({ commit }, { keepVisible, containerName, items, selectorElement, callbackName }) {
        commit('setState', {
            keepVisible: keepVisible || false,
            containerName,
            items,
            selectorElement,
            callbackName,
            visible: true,
        });
    },
    HIDE_CONTEXT_MENU({ commit }) {
        commit('resetState', initialState());
    },
    SELECT_CONTEXT_MENU_ITEM({ commit }, { value, index }) {
        commit('setContextMenuSelectedItem', { value, index })
    },
    UPDATE_CONTEXT_MENU_ITEMS({ commit }, items) {
        commit('updateItems', items)
    },
    UPDATE_CONTEXT_MENU_ITEM({ commit }, { index, item }) {
        commit('updateItem', { index, item })
    }
};

const getters = {
    contextMenuItems: state => state.items,
    contextMenuVisible: state => state.visible,
    contextMenuSelectorElement: state => state.selectorElement,
    contextMenuCallbackName: state => state.callbackName,
    contextMenuSelectedItem: state => state.selectedItem,
    contextMenuKeepVisible: state => state.keepVisible,
    contextMenuContainerName: state => state.containerName,
};

const mutations = {
    setState(state, data) {
        Object.assign(state, data);
    },
    resetState(state) {
        state.visible = false;
        state.items = [];
        state.selectorElement = undefined;
    },
    setContextMenuSelectedItem(state, item) {
        state.selectedItem = item;
    },
    updateItem(state, { index, item }) {
        state.items = state.items.map((existingItem, i) => {
            if (i === index) {
                return item;
            }
            return existingItem;
        });
    },
    updateItems(state, items) {
        state.items = items;
    }
};

export default {
    state: { ...state },
    actions,
    getters,
    mutations
};
