import qs from 'qs';
import axios from 'axios';

// Endpoints
const API_USERS_ENDPOINT = 'backoffice/service-provider/apiusers';
const SINGLE_API_USER_ENDPOINT = `${API_USERS_ENDPOINT}/:apiUserId`;

const SET_API_KEYS = 'spProducts/developer/SET_API_KEYS';

// initial state
const state = { apiKeys: {} };

// actions
const actions = {
  async GET_API_KEYS({ commit }, data) {
    let params = data;
    try {
      const response = await axios.get(API_USERS_ENDPOINT, {
        params,
        paramsSerializer: params =>
          qs.stringify(params, { allowDots: true, indices: false })
      });
      commit(SET_API_KEYS, {
        count: response.data.count,
        data: response.data.data
      });
    } catch (error) {
      throw 'Error while getting the Api Keys. Try again later.';
    }
  },
  CLEAR_API_KEYS({ commit }) {
    commit(SET_API_KEYS, {});
  },
  async ADD_API_KEY(context, params) {
    try {
      const data = { ...params };
      const response = await axios.post(API_USERS_ENDPOINT, data);
      return response?.data;
    } catch (error) {
      throw 'Error while trying to add a new API Key. Try again later.';
    }
  },
  async DELETE_API_KEY(context, { apiKeyId, spProductId }) {
    try {
      const response = await axios.delete(
        `backoffice/service-provider/apiusers/${apiKeyId}`,
        { data: { spProductId: spProductId } }
      );
      return response?.data;
    } catch (error) {
      throw 'Error while deleting the API Key. Try again later.';
    }
  },
  DELETE_API_KEY_FROM_LIST({ commit, state }, apiKeyId) {
    let apiKeys = JSON.parse(JSON.stringify(state.apiKeys));
    const elementIndex = apiKeys.data.findIndex(c => c._id === apiKeyId);
    if (elementIndex !== -1) {
      apiKeys.data.splice(elementIndex, 1);
      apiKeys.count--;
    }
    commit(SET_API_KEYS, apiKeys);
  },
  async EDIT_API_KEY(context, params) {
    try {
      const data = { ...params };
      const response = await axios.put(
        SINGLE_API_USER_ENDPOINT.replace(':apiUserId', data._id),
        data
      );
      return response?.data;
    } catch (error) {
      throw 'Error while editing the API Key. Try again later.';
    }
  },
  ADD_API_KEY_TO_LIST({ commit, state }, apiKey) {
    let apiKeys = JSON.parse(JSON.stringify(state.apiKeys));
    const elementIndex = apiKeys.data.findIndex(c => c._id === apiKey._id);
    const apiKeyToAdd = { ...apiKey };
    if (elementIndex === -1) {
      apiKeys.data.unshift(apiKeyToAdd);
      apiKeys.count++;
    } else {
      apiKeys.data[elementIndex] = apiKeyToAdd;
    }
    commit(SET_API_KEYS, apiKeys);
  }
};

const getters = {
  getApiKeys: state => state.apiKeys
};

const mutations = {
  [SET_API_KEYS](state, data) {
    state.apiKeys = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
