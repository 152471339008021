var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DashboardLayout',{attrs:{"app":_vm.JUPITER_APPS.PAYMENTS}},[_c('template',{slot:"links"},[(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.dashboard'))?_c('sidebar-item',{attrs:{"link":{
        name: 'Dashboard',
        icon: 'tim-icons icon-chart-bar-32',
        path: '/dashboard'
      }}}):_vm._e(),(
        _vm.loggedInUser.acl.some(a => a.role === 'service-provider') &&
        _vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.service-provider.accounts')
      )?_c('sidebar-item',{attrs:{"link":{
        name: 'Accounts',
        icon: 'fal fa-address-card',
        path: '/accounts-center'
      }}}):(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.merchant.accounts'))?_c('sidebar-item',{attrs:{"link":{
        name: 'Accounts',
        icon: 'fal fa-address-card',
        path: '/accounts-view'
      }}}):_vm._e(),(
        _vm.loggedInUser.acl.some(a => a.role === 'service-provider') &&
        _vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.service-provider.products')
      )?_c('sidebar-item',{attrs:{"link":{
        name: 'Products',
        icon: 'tim-icons icon-molecule-40',
        path: '/products'
      }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.invites'))?_c('sidebar-item',{attrs:{"link":{
        name: 'Invites',
        icon: 'fal fa-envelope',
        path: '/invites'
      }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.disputes'))?_c('sidebar-item',{attrs:{"link":{
        name: 'Disputes',
        icon: 'fal fa-bell-exclamation',
        path: '/disputes'
      }}}):_vm._e(),(
        _vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.customers', _vm.FEATURES.CUSTOMERS)
      )?_c('sidebar-item',{attrs:{"link":{
        name: 'Customers',
        icon: 'fal fa-user-tag',
        path: '/customers'
      }}}):_vm._e(),(
        _vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.virtualTerminal', _vm.FEATURES.VIRTUAL_TERMINAL)
      )?_c('sidebar-item',{attrs:{"link":{
        name: 'Virtual Terminal',
        icon: 'fal fa-cash-register',
        path: '/virtual-terminal'
      }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.invoices', _vm.FEATURES.INVOICES))?_c('sidebar-item',{attrs:{"link":{ name: 'Invoices', icon: 'fal fa-file-invoice', path: '/invoices' }}}):_vm._e(),(
        ![_vm.ENVIRONMENTS.PROD, _vm.ENVIRONMENTS.SANDBOX].includes(_vm.$config.ENV) &&
        _vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.subscriptions', _vm.FEATURES.SUBSCRIPTIONS)
      )?_c('sidebar-item',{attrs:{"link":{
        name: 'Subscriptions',
        icon: 'fal fa-timer',
        path: '/subscriptions/plans'
      }}}):_vm._e(),(
        _vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.hostedSessions') ||
        _vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'hosted.paylink') ||
        _vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.transactions') ||
        _vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.declined')
      )?_c('sidebar-item',{attrs:{"link":{ name: 'Payments', icon: 'fal fa-shopping-cart' }}},[(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.transactions'))?_c('sidebar-item',{attrs:{"link":{ name: 'Transactions', path: '/reporting/transactions' }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.hostedSessions'))?_c('sidebar-item',{attrs:{"link":{ name: 'Hosted Sessions', path: '/reporting/hosted-sessions' }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'hosted.paylink', _vm.FEATURES.PAY_LINKS))?_c('sidebar-item',{attrs:{"link":{ name: 'Send Payment Link', path: '/send/payment-link' }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.declined'))?_c('sidebar-item',{attrs:{"link":{ name: 'Declined', path: '/reporting/transactions/declines' }}}):_vm._e()],1):_vm._e(),(
        _vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.settlements') ||
        _vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.fees') ||
        _vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.payouts')
      )?_c('sidebar-item',{attrs:{"link":{ name: 'Reporting', icon: 'fal fa-tasks' }}},[(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.fees'))?_c('sidebar-item',{attrs:{"link":{ name: 'Fees', path: '/reporting/fees' }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.payouts', _vm.FEATURES.PAYOUTS))?_c('sidebar-item',{attrs:{"link":{ name: 'Payouts', path: '/reporting/payouts' }}}):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.settlements'))?_c('sidebar-item',{attrs:{"link":{ name: 'Settlement', path: '/reporting/settlement' }}}):_vm._e()],1):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.users'))?_c('sidebar-item',{attrs:{"link":{ name: 'Users', icon: 'fal fa-user-cog' }}},[(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.usersManage'))?_c('sidebar-item',{attrs:{"link":{ name: 'Manage', path: '/users/manage' }}}):_vm._e()],1):_vm._e(),(_vm.UI_ABILITIES.check(_vm.loggedInUser.acl, 'sidebar.pci-compliance'))?_c('sidebar-item',{attrs:{"link":{
        name: 'PCI Compliance',
        path: '/pci-compliance',
        icon: 'fal fa-shield-check'
      }}}):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }