import axios from 'axios';
import qs from 'qs';

// Mutation types
const SET_SYNCS = 'SYNCS/SET_SYNCS';

const BASE_SYNCS_ENDPOINT = `backoffice/syncs`;

const ENDPOINTS = {
  GET_SYNCS: BASE_SYNCS_ENDPOINT
};

function initialState() {
  return {
    syncsResults: []
  };
}

// initial state
const state = initialState();

// actions
const actions = {
  async GET_SYNCS({ commit }, data) {
    let params = data;
    let response = null;
    try {
      const response = await axios.post(ENDPOINTS.GET_SYNCS, {
        params,
        paramsSerializer: params =>
          qs.stringify(params, { indices: false, allowDots: true })
      });
      let results = response.data.success ? response.data.data : [];
      commit(SET_SYNCS, results);
    } catch (error) {
      if (response) throw response?.data?.message;
      throw error;
    }
  },
  async CLEAR_SYNCS({ commit }) {
    try {
      commit(SET_SYNCS, []);
    } catch (error) {
      throw error;
    }
  }
};

// getters
const getters = {
  getSyncsResults: state => state.syncsResults
};

// mutations
const mutations = {
  [SET_SYNCS](state, data) {
    state.syncsResults = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
