import { TRANSACTION_SOURCE } from '@commons/data/constants';
import { DateTime } from 'luxon';

export const Helpers = {
  methods: {
    $notifySuccess(text) {
      this.$notification('success', text);
    },
    $notifyError(text) {
      this.$notification('danger', text);
    },
    $notifyInfo(text) {
      this.$notification('info', text);
    },
    $notification(type, text, timeout = 5000) {
      this.$notify({
        message: text,
        timeout,
        icon: this._iconByType(type),
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: type
      });
    },
    _iconByType(type) {
      switch (type) {
        case 'success':
          return 'fa fa-check-circle';
        case 'info':
          return 'fa fa-bell';
        case 'warning':
          return 'fa fa-circle-exclamation';
        case 'danger':
          return 'fa fa-circle-xmark';
        default:
          return undefined;
      }
    },
    /**
     * Formats a date according to the specified format.
     *
     * @param {Date|string} date - the date to format, js date object or ISO string
     * @param {string} format - the format to use (optional, default is 'LLLL')
     * @return {string} the formatted date
     */
    $formatDate(date, format) {
      const toFormat = format || 'LLLL';
      if (typeof date === 'string') {
        const isoDate = DateTime.fromISO(date);
        return isoDate.isValid ? isoDate.toFormat(toFormat) : 'Invalid date';
      } else if (date instanceof Date && !isNaN(date)) {
        return DateTime.fromJSDate(date).toFormat(toFormat);
      } else {
        return 'Invalid date';
      }
    },
    $getBadgeType(operation) {
      switch (operation) {
        case 'FullCredit':
        case 'PartialCredit':
          return 'dark-yellow';
        case 'PartialCancelAuthorization':
        case 'CancelAuthorization':
          return 'orange';
        default:
          return 'info';
      }
    },
    $formatCardNumber(number, method, cardType) {
      if (!number) return '';
      let formattedReturn = number.slice(-4);
      if (method) formattedReturn = `${method}-${formattedReturn}`;
      if (cardType) formattedReturn = `${cardType}-${formattedReturn}`;
      return formattedReturn;
    },
    $formatTransactionId(id) {
      if (!id) return '';
      const splittedId = id.split('');
      let first3chars = id.slice(0, 3);
      let firstNonZeroChar = 3;
      for (let i = 4; i < splittedId.length; i++) {
        if (splittedId[i] !== '0') {
          firstNonZeroChar = i;
          break;
        }
      }
      return `${first3chars}-${id.slice(firstNonZeroChar)}`;
    },
    $formatCurrency(amount) {
      return amount || amount === 0
        ? new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(amount)
        : '';
    },
    $formatSubmerchantsIds(smIds) {
      if (!smIds) return;
      const inputIds = Array.isArray(smIds) ? smIds : [smIds];

      return this.accountNames.flatMap(subMerchant => {
        if (!subMerchant.identity) return [];
        const snName = subMerchant.identity.business.dbaName;
        const smId = subMerchant.subMerchantId;
        return inputIds.flatMap(inputItem => {
          if (inputItem.startsWith('/')) {
            const match = inputItem.match(/^\/(.*?)\/([gimsuy]*)$/);
            if (match) {
              const [pattern, flags] = match.slice(1);
              if (pattern === smId || new RegExp(pattern, flags).test(snName)) return smId;
            }
          } else if (inputItem === smId) {
            return smId;
          } else if (inputItem === snName) {
            return smId;
          }
          return [];
        });
      }).filter(Boolean).join() || ' ';
    },

    $formatCustomersIds(customerIds) {
      if (!customerIds) {
        return;
      }
      if (Array.isArray(customerIds)) {
        customerIds = customerIds.join(',');
      }
      customerIds = customerIds.split(',');
      customerIds = this.getCustomers.data.map(customer => {
        const customerName = customer.name;
        const { _id: customerId } = customer;
        for (const inputItem of customerIds) {
          let input;
          if (inputItem.startsWith('/')) {
            const match = inputItem.match(/^\/(.*?)\/([gimsuy]*)$/);
            if (match) {
              const [, pattern, flags] = match;
              input = new RegExp(pattern, flags);
            }
          } else {
            input = new RegExp(inputItem, 'i');
          }
          if (input && (input.test(customerName) || customerId === inputItem)) {
            return customerId;
          }
        }
      });
      return customerIds.filter(Boolean).join() || ' ';
    },

    $getSubMerchantIdsForSingleSearch(smIds) {
      return (
        this.$formatSubmerchantsIds(smIds)
          .split(',')
          .filter(i => i !== '0')
          .join() || ' '
      );
    },

    $parseApiLogJson(data) {
      if (typeof data === 'string') {
        const trimmedData = data.trim();
        if (
          (trimmedData.startsWith('{') || trimmedData.startsWith('[')) &&
          (trimmedData.endsWith('}') || trimmedData.endsWith(']'))
        ) {
          try {
            return JSON.parse(trimmedData);
          } catch (error) {
            console.error('Failed to parse JSON:', error);
            return data;
          }
        } else {
          return data;
        }
      } else if (typeof data === 'object' && data !== null) {
        const newData = {};
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            newData[key] = data[key];
          }
        }
        return newData;
      }
      return data;
    }
  }
};

export const $getSourceIconClass = source => {
  switch (source) {
    case TRANSACTION_SOURCE.API:
      return 'tim-icons icon-molecule-40';
    case TRANSACTION_SOURCE.BACKOFFICE:
      return 'fal fa-cash-register';
    case TRANSACTION_SOURCE.CHECKOUT:
      return 'fal fa-shopping-cart';
    case TRANSACTION_SOURCE.PAYLINK:
      return 'fal fal fa-link';
    case TRANSACTION_SOURCE.INVOICE:
      return 'fal fa-file-invoice';
    case TRANSACTION_SOURCE.SUBSCRIPTION:
      return 'fal fa-timer';
    default:
      break;
  }
};
