import config from '@/config';
import DashboardLayout from '@/pages/Layout/DashboardLayout';
import { COMMONS_URLS, ENVIRONMENTS, FEATURES } from '@commons/data/constants';
import { UI_ABILITIES } from '@commons/data/role-ui-abilities';
import NotFound from '@commons/pages/Common/NotFoundPage';
import AuthLayout from '@commons/pages/Layout/AuthLayout';
import store from '@commons/store';

const Profile = () =>
  import(/* webpackChunkName: "pages" */ `@commons/pages/User/Profile`);
const ChangePassword = () =>
  import(/* webpackChunkName: "pages" */ `@commons/pages/User/ChangePassword`);
// Dashboard
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ `@commons/pages/Dashboard/Dashboard`);
// Reporting
const ReportingLayout = () =>
  import(/* webpackChunkName: "dashboard" */ `@commons/pages/Reporting/ReportingLayout`);
const TransactionsPage = () =>
  import(
    /* webpackChunkName: "dashboard" */ `@commons/pages/Reporting/Transactions/TransactionsPage`
  );
const Payouts = () =>
  import(/* webpackChunkName: "dashboard" */ `@commons/pages/Reporting/PayoutsPage`);
const Fees = () =>
  import(/* webpackChunkName: "dashboard" */ `@commons/pages/Reporting/FeesPage`);
const SettlementPage = () =>
  import(/* webpackChunkName: "dashboard" */ `@commons/pages/Reporting/SettlementPage`);
const Customers = () =>
  import(/* webpackChunkName: "dashboard" */ `@commons/pages/Customers/Customers`);
// Sub Accounts
const Accounts = () =>
  import(/* webpackChunkName: "dashboard" */ `@commons/pages/Accounts/Accounts`);
const StatusOk = () =>
  import(/* webpackChunkName: "dashboard" */ `@commons/pages/Status/StatusOk`);

const AccountsCenter = () =>
  import(
    /* webpackChunkName: "dashboard" */ `@/pages/Accounts/AccountsCenter/AccountsCenter`
  );
const AccountsView = () =>
  import(
    /* webpackChunkName: "dashboard" */ `@/pages/Accounts/AccountsView/AccountsView`
  );

const AccountSPOverview = () =>
  import(
    /* webpackChunkName: "dashboard" */ `@/pages/Accounts/AccountsCenter/AccountSPOverview`
  );
const AccountMerchantOverview = () =>
  import(
    /* webpackChunkName: "dashboard" */ `@/pages/Accounts/AccountsView/AccountMerchantOverview`
  );

const VirtualTerminal = () => import(`@commons/pages/VirtualTerminal/VirtualTerminal`);
const ApplePayTerminal = () => import(`@commons/pages/VirtualTerminal/ApplePayTerminal`);
const Forbidden = () =>
  import(/* webpackChunkName: "pages" */ `@commons/pages/Forbidden`);
const Manage = () =>
  import(/* webpackChunkName: "users" */ `@commons/pages/Users/Manage`);
// const UsersActivities = () =>
//   import(/* webpackChunkName: "users" */ `@commons/pages/Users/UserActivitiesPage`);
const Disputes = () =>
  import(/* webpackChunkName: "dashboard" */ `@commons/pages/Disputes/Disputes`);
const Risks = () =>
  import(/* webpackChunkName: "risks" */ `@commons/pages/Risks/RisksPage`);
const Rules = () => import(/* webpackChunkName: "risks" */ `@commons/pages/Risks/Rules`);
const Processing = () =>
  import(/* webpackChunkName: "risks" */ `@commons/pages/Risks/Processing`);
const NewAccount = () =>
  import(/* webpackChunkName: "status" */ `@commons/pages/Accounts/NewAccount`);
const ManualPayouts = () =>
  import(
    /* webpackChunkName: "ManualPayouts" */ `@commons/pages/ManualPayouts/ManualPayouts`
  );
const DevLogin = () =>
  import(/* webpackChunkName: "DevLogin" */ `@commons/pages/User/DevLogin`);
const HostedPaylink = () =>
  import(/* webpackChunkName: "Hosted" */ `@commons/pages/Hosted/PaylinkPage`);
const HostedInvoice = () =>
  import(/* webpackChunkName: "Hosted" */ `@commons/pages/Hosted/HostedInvoicePage`);
const HostedSessions = () =>
  import(/* webpackChunkName: "Hosted" */ `@commons/pages/Reporting/HostedSessionsPage`);
const Invoices = () => import('@commons/pages/Invoice/InvoicesPage');
const NewInvoice = () => import('@commons/pages/Invoice/InvoiceForm');
const Declines = () =>
  import(
    /* webpackChunkName: "Transactions" */ `@commons/pages/Reporting/Transactions/DeclinedPage`
  );
const InvitesPage = () =>
  import(
    /* webpackChunkName: "Invites" */ `@/pages/OnboardingInvites/OnboardingInvitesPage`
  );
const Developer = () => import('@/pages/Developer/DeveloperPage');
const ApiLogs = () => import('@commons/pages/Developer/ApiLogs/ApiLogsPage');
const ApiKeys = () => import('@/pages/Developer/ApiKeys/ApiKeysPage');
const SPWebhooks = () => import('@commons/pages/Developer/Webhooks/SPWebhooksPage');

const subscriptionLayout = () =>
  import(
    /* webpackChunkName: "subscriptions" */ `@commons/pages/Subscriptions/subscriptionLayout`
  );

const Plans = () =>
  import(/* webpackChunkName: "Plans" */ `@commons/pages/Subscriptions/Plans`);

const Plan = () =>
  import(/* webpackChunkName: "Plan" */ `@commons/pages/Subscriptions/Plan`);

const PCICompliance = () => import('@/pages/PCICompliance');

const NotificationEventsPage = () =>
  import(
    /* webpackChunkName: "NotificationEventsPage" */ `@commons/pages/NotificationEvents/NotificationEventsPage`
  );
const AlertsPage = () =>
  import(/* webpackChunkName: "AlertsPage" */ `@commons/pages/Alerts/AlertsPage`);

const Products = () =>
  import(/* webpackChunkName: "Products" */ '@/pages/Products/Products');
const ProductOverview = () =>
  import(/* webpackChunkName: "Products" */ '@/pages/Products/ProductOverview');

const isUserAuthenticated = () => {
  return store.getters.isAuthenticated;
};

const validateUserSession = (to, from, next) => {
  if (!isUserAuthenticated()) {
    if (config.ENV !== ENVIRONMENTS.LOCAL || !config.BYPASS_COMMONS_LOGIN)
      return window.location.replace(COMMONS_URLS.LOGIN_PAYMENTS);
    else return next({ name: 'DevLogin' });
  }
  next();
};

const ifNotAuthenticated = (to, from, next) => {
  if (!isUserAuthenticated()) return next();
  next('/');
};

const validateUserSessionAndRol = (to, from, next, page, feature = '') => {
  if (!isUserAuthenticated()) {
    if (config.ENV !== ENVIRONMENTS.LOCAL || !config.BYPASS_COMMONS_LOGIN)
      return window.location.replace(COMMONS_URLS.LOGIN_PAYMENTS);
    else return next({ name: 'DevLogin' });
  }
  const user = store.getters.loggedInUser;
  if (!UI_ABILITIES.check(user.acl, page, feature)) return next('/forbidden');
  next();
};

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home',
    beforeEnter: validateUserSession
  },
  {
    path: '/',
    component: AuthLayout,
    name: 'Authentication',
    children: [
      ...(config.ENV !== ENVIRONMENTS.PROD
        ? [
            {
              path: '/dev/login',
              name: 'DevLogin',
              component: DevLogin,
              beforeEnter: ifNotAuthenticated
            }
          ]
        : [])
    ]
  },
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Root',
    beforeEnter: validateUserSession,
    children: [
      {
        path: '/status/ok',
        name: 'StatusOk',
        components: { default: StatusOk },
        meta: {
          title: 'Status'
        }
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        components: { default: Dashboard },
        meta: { showFooter: true },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'sidebar.dashboard')
      },
      {
        path: 'invites',
        name: 'Invites',
        components: { default: InvitesPage },
        meta: {
          title: 'Invites'
        },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'sidebar.invites')
      },
      {
        path: 'products',
        name: 'Products',
        components: { default: Products },
        meta: {
          title: 'Products'
        },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'sidebar.service-provider.products')
      },
      {
        path: 'products/:id/overview',
        name: 'ProductOverview',
        components: { default: ProductOverview },
        props: true,
        meta: {
          title: 'Product Overview'
        }
      },
      {
        path: 'accounts',
        name: 'Accounts',
        components: { default: Accounts },
        meta: {
          title: 'Accounts'
        },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'sidebar.accounts')
      },
      {
        path: 'accounts/new',
        name: 'NewAccount',
        component: NewAccount,
        props: true,
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'accounts.new')
      },
      {
        path: 'service-provider/account/:id/overview',
        name: 'AccountSPOverview',
        components: { default: AccountSPOverview },
        meta: {
          title: 'Account Overview'
        },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'service-provider.account.overview')
      },
      {
        path: 'merchant/account/:id/overview',
        name: 'AccountMerchantOverview',
        components: { default: AccountMerchantOverview },
        meta: { title: 'Account Overview' },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'merchant.account.overview')
      },
      {
        path: 'customers',
        name: 'Customers',
        components: { default: Customers },
        meta: {
          title: 'Customers'
        },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(
            to,
            from,
            next,
            'sidebar.customers',
            FEATURES.CUSTOMERS
          )
      },
      {
        path: 'disputes',
        name: 'Disputes',
        components: { default: Disputes },
        meta: {
          title: 'Disputes'
        },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'sidebar.disputes')
      },
      {
        path: 'users/manage',
        name: 'Manage',
        components: { default: Manage },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'sidebar.usersManage'),
        meta: {
          title: 'Manage'
        }
      },
      // {
      //   path: 'users/useractivities',
      //   name: 'Users Acitivities',
      //   components: { default: UsersActivities },
      //   beforeEnter: (to, from, next) =>
      //     validateUserSessionAndRol(to, from, next, 'sidebar.usersActivities'),
      //   meta: {
      //     title: 'Users Activities'
      //   }
      // },
      {
        path: 'virtual-terminal',
        name: 'VirtualTerminal',
        components: { default: VirtualTerminal },
        beforeEnter: (to, from, next) => {
          validateUserSessionAndRol(
            to,
            from,
            next,
            'sidebar.virtualTerminal',
            FEATURES.VIRTUAL_TERMINAL
          );
        }
      },
      {
        path: 'invoices',
        name: 'Invoices',
        components: { default: Invoices },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'sidebar.invoices', FEATURES.INVOICES)
      },
      {
        path: 'invoices/new',
        name: 'NewInvoice',
        components: { default: NewInvoice },
        props: true,
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'sidebar.invoices', FEATURES.INVOICES)
      },
      {
        path: 'invoices/:id',
        name: 'EditInvoice',
        components: { default: NewInvoice },
        props: true,
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'sidebar.invoices', FEATURES.INVOICES)
      },
      {
        path: 'apple-pay',
        name: 'ApplePay',
        components: { default: ApplePayTerminal },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'sidebar.applePay')
      },
      {
        path: 'profile',
        name: 'Profile',
        components: { default: Profile },
        meta: {
          title: 'Profile'
        }
      },
      {
        path: 'change-password',
        name: 'ChangePassword',
        components: { default: ChangePassword },
        meta: {
          title: 'Change Password'
        }
      },
      {
        path: 'subscriptions',
        name: 'Subscriptions',
        components: { default: subscriptionLayout },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(
            to,
            from,
            next,
            'sidebar.subscriptions',
            FEATURES.SUBSCRIPTIONS
          ),
        children: [
          {
            path: 'plans',
            name: 'Plans',
            components: {
              default: Plans
            },
            meta: {
              title: 'Plans'
            }
          },
          {
            path: 'plans/new',
            name: 'NewPlan',
            components: {
              default: Plan
            },
            meta: {
              title: 'New Plan'
            }
          },
          {
            path: 'plans/:id',
            name: 'EditPlan',
            components: {
              default: Plan
            },
            props: true,
            meta: {
              title: 'Edit Plan'
            }
          }
        ]
      },
      {
        path: 'inbox',
        name: 'Inbox',
        components: { default: NotificationEventsPage },
        meta: {
          title: 'Inbox'
        }
        // beforeEnter: (to, from, next) =>
        //   validateUserSessionAndRol(to, from, next, 'sidebar.notificationEvents')
      },
      {
        path: 'reporting',
        name: 'Reporting',
        components: { default: ReportingLayout },
        children: [
          {
            path: 'transactions',
            name: 'Transactions',
            components: { default: TransactionsPage },
            meta: { title: 'Transactions' },
            beforeEnter: (to, from, next) =>
              validateUserSessionAndRol(to, from, next, 'sidebar.transactions')
          },
          {
            path: 'transactions/declines',
            name: 'Declines',
            components: { default: Declines },
            meta: { title: 'Declines' },
            beforeEnter: (to, from, next) =>
              validateUserSessionAndRol(to, from, next, 'sidebar.declined')
          },
          {
            path: 'hosted-sessions',
            name: 'HostedSessions',
            components: { default: HostedSessions },
            meta: { title: 'HostedSessions' },
            beforeEnter: (to, from, next) =>
              validateUserSessionAndRol(to, from, next, 'sidebar.hostedSessions')
          },
          {
            path: 'payouts',
            name: 'Payouts',
            components: { default: Payouts },
            meta: { title: 'Payouts' },
            beforeEnter: (to, from, next) =>
              validateUserSessionAndRol(
                to,
                from,
                next,
                'sidebar.payouts',
                FEATURES.PAYOUTS
              )
          },
          {
            path: 'manual-payouts',
            name: 'ManualPayouts',
            components: { default: ManualPayouts },
            meta: { title: 'ManualPayouts' },
            beforeEnter: (to, from, next) =>
              validateUserSessionAndRol(
                to,
                from,
                next,
                'sidebar.manualPayouts',
                FEATURES.PAYOUTS
              )
          },
          {
            path: 'settlement',
            name: 'Settlement',
            components: { default: SettlementPage },
            meta: { title: 'Settlement' },
            beforeEnter: (to, from, next) =>
              validateUserSessionAndRol(to, from, next, 'sidebar.settlements')
          },
          {
            path: 'fees',
            name: 'Fees',
            components: { default: Fees },
            meta: { title: 'Fees' },
            beforeEnter: (to, from, next) =>
              validateUserSessionAndRol(to, from, next, 'sidebar.fees')
          }
        ]
      },
      {
        path: 'developer',
        name: 'Developer',
        components: { default: Developer },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'sidebar.developer'),
        meta: { title: 'Developer' },
        children: [
          {
            path: 'api-logs',
            name: 'ApiLogs',
            components: { default: ApiLogs },
            meta: { title: 'Api Logs' }
          },
          {
            path: 'api-keys',
            name: 'ApiKeys',
            components: { default: ApiKeys },
            meta: { title: 'Api Keys' }
          },
          {
            path: 'webhooks',
            name: 'Webhooks',
            components: { default: SPWebhooks },
            meta: { title: 'Webhooks' }
          }
        ]
      },
      {
        path: 'risk-management',
        name: 'RiskManagement',
        components: { default: ReportingLayout },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'sidebar.risks'),
        children: [
          {
            path: 'transactions',
            name: 'RiskManagement/Transactions',
            components: { default: Risks },
            meta: { title: 'Risks Transactions' }
          },
          {
            path: 'rules',
            name: 'Rules',
            components: { default: Rules },
            meta: { title: 'Rules' }
          },
          {
            path: 'alerts',
            name: 'Alerts',
            components: { default: AlertsPage },
            meta: { title: 'Alerts' }
          },
          {
            path: 'processing',
            name: 'RiskManagement/Processing',
            components: { default: Processing },
            meta: { title: 'Processing' }
          }
        ]
      },
      {
        path: 'accounts-center',
        name: 'AccountsCenter',
        components: { default: AccountsCenter },
        meta: { title: 'Accounts' },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'sidebar.service-provider.accounts')
      },
      {
        path: 'accounts-view',
        name: 'AccountsView',
        components: { default: AccountsView },
        meta: { title: 'Accounts' },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'sidebar.merchant.accounts')
      },
      {
        path: 'send/payment-link',
        name: 'Paylink',
        components: { default: HostedPaylink },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'hosted.paylink', FEATURES.PAY_LINKS)
      },
      // {
      //   path: 'send/invoice',
      //   name: 'HostedInvoice',
      //   components: { default: HostedInvoice },
      //   beforeEnter: (to, from, next) => {
      //     if (config.ENV === ENVIRONMENTS.LOCAL || config.ENV === ENVIRONMENTS.DEV) {
      //       validateUserSessionAndRol(to, from, next, 'hosted.invoice');
      //     } else {
      //       next('/forbidden');
      //     }
      //   }
      // },
      {
        path: 'pci-compliance',
        name: 'PCICompliance',
        components: { default: PCICompliance },
        meta: {
          title: 'PCI Compliance'
        },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'sidebar.pci-compliance')
      },
      {
        path: 'forbidden',
        name: 'Forbidden',
        components: { default: Forbidden },
        meta: {
          title: 'Forbidden Page'
        }
      }
    ]
  },
  { path: '*', component: NotFound }
];

export default routes;
