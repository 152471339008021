export const CNP_AUTHORIZATION = 'ccCnpAuth';
export const CNP_AVS = 'ccCnpAvsOnly';
export const CNP_CAPTURE = 'ccCnpCapture';
export const CNP_CREDIT = 'ccCnpCredit';
export const CNP_CHARGEBACK = 'ccCnpChargeback';
export const CNP_VOID = 'ccCnpVoid';
export const CNP_SALE = 'ccCnpSale';
export const CNP_REFUND = 'ccCnpRefund';
export const CNP_REVERSAL = 'ccCnpReversal';
export const CHECK_VERIFICATION = 'checkVerification';
export const CHECK_CREDIT = 'checkCredit';
export const CHECK_VOID = 'checkVoid';
export const CHECK_SALE = 'checkSale';
export const CHECK_REFUND = 'checkRefund';
export const CHECK_REVERSAL = 'checkReversal';
export const CHECK_RECONCILED = 'checkReconciled';
export const PRETTY_CNP_AUTHORIZATION = 'Authorization';
export const PRETTY_CNP_AVS = 'Avs';
export const PRETTY_CNP_CAPTURE = 'Capture';
export const PRETTY_CNP_CREDIT = 'Credit';
export const PRETTY_CNP_CHARGEBACK = 'Chargeback';
export const PRETTY_CNP_VOID = 'Void';
export const PRETTY_CNP_SALE = 'Sale';
export const PRETTY_CNP_REFUND = 'Refund';
export const PRETTY_CNP_REVERSAL = 'Reversal';
export const PRETTY_CHECK_VERIFICATION = 'Verification';
export const PRETTY_CHECK_CREDIT = 'Credit';
export const PRETTY_CHECK_VOID = 'Void';
export const PRETTY_CHECK_SALE = 'Sale';
export const PRETTY_CHECK_REFUND = 'Refund';
export const PRETTY_CHECK_REVERSAL = 'Reversal';
export const PRETTY_CHECK_RECONCILED = 'Reconciled';
export const PRETTY_CNP_AUTHORIZATION_NO_CC = 'authorization';
export const PRETTY_CNP_AVS_NO_CC = 'avs';
export const PRETTY_CNP_CAPTURE_NO_CC = 'capture';
export const PRETTY_CNP_CREDIT_NO_CC = 'credit';
export const PRETTY_CNP_CHARGEBACK_NO_CC = 'chargeback';
export const PRETTY_CNP_VOID_NO_CC = 'void';
export const PRETTY_CNP_SALE_NO_CC = 'sale';
export const PRETTY_CNP_REFUND_NO_CC = 'refund';
export const PRETTY_CNP_REVERSAL_NO_CC = 'reversal';
export const PRETTY_CHECK_VERIFICATION_NO_CC = 'check verification';
export const PRETTY_CHECK_CREDIT_NO_CC = 'check credit';
export const PRETTY_CHECK_VOID_NO_CC = 'check void';
export const PRETTY_CHECK_SALE_NO_CC = 'check sale';
export const PRETTY_CHECK_REFUND_NO_CC = 'check refund';
export const PRETTY_CHECK_REVERSAL_NO_CC = 'check reversal';
export const PRETTY_CHECK_RECONCILED_NO_CC = 'reconciled';

export const getColors = status => {
  switch (status) {
    case 'reconciled':
      return { bg: 'rgb(138,84,245)', color: 'white' };
    case 'paid':
      return { bg: 'rgb(29,140,248)', color: 'white' };
    case 'pending':
      return { bg: 'rgb(255,141,114)', color: 'white' };
    case 'on-hold':
      return { bg: 'rgb(255,141,114)', color: 'white' };
    case 'ack':
      return { bg: 'rgb(0,242,195)', color: 'white' };
    case 'approved':
      return { bg: '#26AE5F', color: '#FDFEFE' };
    case 'processing':
      return { bg: '#5e72e4', color: 'white' };
    case 'instructed':
      return { bg: 'rgb(253,93,147)', color: 'white' };
    case 'calculatingFees':
      return { bg: 'black', color: 'white' };
    default:
      return { bg: 'black', color: 'white' };
  }
};
export const processingStatus = {
  ack: 'In Transit',
  instructed: 'In Process',
  pending: 'Pending',
  onHold: 'On-hold',
  approved: 'Approved',
  processing: 'Processing',
  processed: 'Processed',
  instruction: 'In Transit',
  sent: 'Paid',
  reconciled: 'Reconciled'
};

export const getStatusUIName = status => {
  if (!status) return 'Unknown';
  switch (status) {
    case 'ack':
      return 'In Transit';
    case 'instructed':
      return 'In Process';
    case 'pending':
      return 'Pending';
    case 'on-hold':
      return 'On-hold';
    case 'approved':
      return 'Approved';
    case 'processing':
      return 'Processing';
    case 'processed':
      return 'Processed';
    case 'instruction':
      return 'In Transit';
    case 'sent':
      return 'Paid';
    case 'reconciled':
      return 'Reconciled';
    default:
      return status.toString().toUpperCase();
  }
};

export const getTransactionType = (transactionType, noCapitalLetter = false) => {
  switch (transactionType) {
    case PRETTY_CNP_CHARGEBACK:
      return CNP_CHARGEBACK;
    case CNP_CHARGEBACK:
      return PRETTY_CNP_CHARGEBACK;
    case PRETTY_CNP_AUTHORIZATION:
      return CNP_AUTHORIZATION;
    case CNP_AUTHORIZATION:
      if (noCapitalLetter) return PRETTY_CNP_AUTHORIZATION_NO_CC;
      return PRETTY_CNP_AUTHORIZATION;
    case PRETTY_CNP_AVS:
      return CNP_AVS;
    case CNP_AVS:
      if (noCapitalLetter) return PRETTY_CNP_AVS_NO_CC;
      return PRETTY_CNP_AVS;
    case PRETTY_CNP_CAPTURE:
      return CNP_CAPTURE;
    case CNP_CAPTURE:
      if (noCapitalLetter) return PRETTY_CNP_CAPTURE_NO_CC;
      return PRETTY_CNP_CAPTURE;
    case PRETTY_CNP_CREDIT:
      return CNP_CREDIT;
    case CNP_CREDIT:
      if (noCapitalLetter) return PRETTY_CNP_CREDIT_NO_CC;
      return PRETTY_CNP_CREDIT;
    case PRETTY_CNP_VOID:
      return CNP_VOID;
    case CNP_VOID:
      if (noCapitalLetter) return PRETTY_CNP_VOID_NO_CC;
      return PRETTY_CNP_VOID;
    case PRETTY_CNP_SALE:
      return CNP_SALE;
    case CNP_SALE:
      if (noCapitalLetter) return PRETTY_CNP_SALE_NO_CC;
      return PRETTY_CNP_SALE;
    case PRETTY_CNP_REFUND:
      return CNP_REFUND;
    case CNP_REFUND:
      if (noCapitalLetter) return PRETTY_CNP_REFUND_NO_CC;
      return PRETTY_CNP_REFUND;
    case PRETTY_CNP_REVERSAL:
      return CNP_REVERSAL;
    case CNP_REVERSAL:
      if (noCapitalLetter) return PRETTY_CNP_REVERSAL_NO_CC;
      return PRETTY_CNP_REVERSAL;
    case PRETTY_CHECK_VERIFICATION:
      return CHECK_VERIFICATION;
    case CHECK_VERIFICATION:
      if (noCapitalLetter) return PRETTY_CHECK_VERIFICATION_NO_CC;
      return PRETTY_CHECK_VERIFICATION;
    case PRETTY_CHECK_CREDIT:
      return CHECK_CREDIT;
    case CHECK_CREDIT:
      if (noCapitalLetter) return PRETTY_CHECK_CREDIT_NO_CC;
      return PRETTY_CHECK_CREDIT;
    case PRETTY_CHECK_VOID:
      return CHECK_VOID;
    case CHECK_VOID:
      if (noCapitalLetter) return PRETTY_CHECK_VOID_NO_CC;
      return PRETTY_CHECK_VOID;
    case PRETTY_CHECK_SALE:
      return CHECK_SALE;
    case CHECK_SALE:
      if (noCapitalLetter) return PRETTY_CHECK_SALE_NO_CC;
      return PRETTY_CHECK_SALE;
    case PRETTY_CHECK_REFUND:
      return CHECK_REFUND;
    case CHECK_REFUND:
      if (noCapitalLetter) return PRETTY_CHECK_REFUND_NO_CC;
      return PRETTY_CHECK_REFUND;
    case PRETTY_CHECK_REVERSAL:
      return CHECK_REVERSAL;
    case CHECK_REVERSAL:
      if (noCapitalLetter) return PRETTY_CHECK_REVERSAL_NO_CC;
      return PRETTY_CHECK_REVERSAL;
    case PRETTY_CHECK_RECONCILED:
      return CHECK_RECONCILED;
    case CHECK_RECONCILED:
      if (noCapitalLetter) return PRETTY_CHECK_RECONCILED_NO_CC;
      return PRETTY_CHECK_RECONCILED;
    default:
      return transactionType;
  }
};
