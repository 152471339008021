import axios from 'axios';
import qs from 'qs';

// Endpoints
const USERS_ENDPOINT = '/users';
const SINGLE_USER_ENDPOINT = '/users/:id';
const USERS_REPORTING_ENDPOINT = '/users';
const USER_PRODUCTS_ENDPOINT = `${SINGLE_USER_ENDPOINT}/products`;
const UNLOCK_USER_ENDPOINT = `${SINGLE_USER_ENDPOINT}/unlock`;

const SET_USERS = 'users/SET_USERS';

// initial state
const state = { users: {} };

// actions
const actions = {
  ADD_USER(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(USERS_ENDPOINT, data)
        .then(response => resolve(response.data))
        .catch(error => reject(getErrorMessage(error)));
    });
  },
  GET_USER(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(SINGLE_USER_ENDPOINT.replace(':id', data), { cancelPreviousRequests: true })
        .then(response => resolve(response.data))
        .catch(() => {
          reject('GET_USER');
        });
    });
  },
  EDIT_USER(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(SINGLE_USER_ENDPOINT.replace(':id', data._id), data)
        .then(response => resolve(response.data))
        .catch(error => reject(getErrorMessage(error)));
    });
  },
  DELETE_USER(context, userId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(SINGLE_USER_ENDPOINT.replace(':id', userId))
        .then(response => resolve(response.data))
        .catch(() => {
          reject('Error while deleting the user. Try again later.');
        });
    });
  },
  GET_USERS({ commit }, data) {
    let params = data;
    if (params.roles) {
      const applications = { payments: { acl: { role: params.roles } } };
      params = { ...params, applications };
      delete params.roles;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(USERS_REPORTING_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true })
        })
        .then(response => {
          let responseData = response.data.data;
          let data = [];

          for (let i = 0; i < responseData.length; i++) {
            data[i] = { ...responseData[i] };
            data[i].roles = [];
            for (let j = 0; j < responseData[i].applications.payments.acl.length; j++) {
              const element = responseData[i].applications.payments.acl[j];
              data[i].roles.push(element.role);
            }
            data[i].roles = data[i].roles.join(', ');
          }
          commit(SET_USERS, { count: response.data.count, data });
          resolve();
        })
        .catch(error => reject(error));
    });
  },
  ADD_USER_TO_LIST({ commit, state }, user) {
    let users = JSON.parse(JSON.stringify(state.users));
    if (users.data) {
      const elementIndex = users.data.findIndex(c => c._id === user._id);
      const userToAdd = { ...user };
      userToAdd.roles = [];
      for (let j = 0; j < userToAdd.applications.payments.acl.length; j++) {
        const element = userToAdd.applications.payments.acl[j];
        userToAdd.roles.push(element.role);
      }
      userToAdd.roles = userToAdd.roles.join(', ');
      if (elementIndex === -1) {
        users.data.unshift(userToAdd);
        users.count++;
      } else {
        users.data[elementIndex] = userToAdd;
      }
      commit(SET_USERS, users);
    }
  },
  DELETE_USER_FROM_LIST({ commit, state }, userId) {
    let users = JSON.parse(JSON.stringify(state.users));
    const elementIndex = users.data.findIndex(c => c._id === userId);
    if (elementIndex !== -1) {
      users.data.splice(elementIndex, 1);
      users.count--;
    }
    commit(SET_USERS, users);
  },
  ADD_SUBPRODUCT(context, { userId, product }) {
    return new Promise((resolve, reject) => {
      axios
        .post(USER_PRODUCTS_ENDPOINT.replace(':id', userId), product)
        .then(response => resolve(response.data))
        .catch(() => {
          reject('Error while trying to add a submerchant. Try again later');
        });
    });
  },
  ADD_PRODUCT_TO_USER({ commit, state }, { userId, product }) {
    let users = JSON.parse(JSON.stringify(state.users));
    const elementIndex = users.data.findIndex(c => c._id === userId);
    if (elementIndex !== -1) {
      users.data[elementIndex].applications.payments.productIds.push(product);
      commit(SET_USERS, users);
    }
  },

  REMOVE_SUBPRODUCT(context, { userId, productData }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${USER_PRODUCTS_ENDPOINT.replace(':id', userId)}/${productData}`)
        .then(response => resolve(response.data))
        .catch(() => {
          reject('Error while trying to remove a submerchant. Try again later');
        });
    });
  },
  UNLOCK_USER(context, userId) {
    return new Promise((resolve, reject) => {
      axios
        .post(UNLOCK_USER_ENDPOINT.replace(':id', userId))
        .then(response => resolve(response.data))
        .catch(error => reject(getErrorMessage(error)));
    });
  }
};

function getErrorMessage(error) {
  if (error && error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  }
  if (error.response) {
    const { data = {} } = error.response;
    if (data.stack.includes('email')) return 'Email already exists';
    else if (data.stack.includes('username')) return 'Username already exists';
    else if (data.stack.includes('account'))
      return 'The user must have an assigned account';
  }
  return 'Error while saving the user. Try again later.';
}

const getters = {
  getUsers: state => state.users
};

const mutations = {
  [SET_USERS](state, data) {
    state.users = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
