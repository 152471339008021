import axios from 'axios';

// Endpoints
const SP_PRODUCTS_ENDPOINT = 'backoffice/service-provider/spproducts';
const SINGLE_SP_PRODUCT_ENDPOINT = `${SP_PRODUCTS_ENDPOINT}/:spProductId`;
const FEES_TEMPLATES_ENDPOINT = `${SINGLE_SP_PRODUCT_ENDPOINT}/feestemplates`;
const ONBOARDING_FLOW_TEMPLATES_ENDPOINT = `${SINGLE_SP_PRODUCT_ENDPOINT}/onboardingflowtemplates`;
const BRANDING_ENDPOINT = `${SINGLE_SP_PRODUCT_ENDPOINT}/branding`;

const SET_FEES_TEMPLATES = 'serviceProvider/spProducts/SET_FEES_TEMPLATES';
const SET_ONBOARDING_FLOW_TEMPLATES =
  'serviceProvider/spProducts/SET_ONBOARDING_FLOW_TEMPLATES';
const SET_WEBHOOKS = 'serviceProvider/spProducts/SET_WEBHOOKS';
const SET_PRODUCTS = 'serviceProvider/spProducts/SET_PRODUCTS';
const SET_BRANDING = 'serviceProvider/spProducts/SET_BRANDING';
const CLEAR_PRODUCTS = 'serviceProvider/spProducts/CLEAR_PRODUCTS';

// initial state
const state = {
  feesTemplates: [],
  onboardingFlowTemplates: [],
  webhooks: {},
  products: []
};

// actions
const actions = {
  async GET_PRODUCTS({ commit }, params) {
    try {
      const response = await axios.get(SP_PRODUCTS_ENDPOINT, { params });
      
      commit(SET_PRODUCTS, {
        count: response.data.count,
        data: response.data.data
      });
      return response.data;
    } catch (error) {
      throw new Error('Error while fetching products. Please try again later.');
    }
  },
  async GET_FEES_TEMPLATES({ commit }, spProductId) {
    try {
      const response = await axios.get(
        FEES_TEMPLATES_ENDPOINT.replace(':spProductId', spProductId)
      );
      commit(SET_FEES_TEMPLATES, response.data.data);
    } catch (error) {
      throw error;
    }
  },
  CLEAR_FEES_TEMPLATES({ commit }) {
    commit(SET_FEES_TEMPLATES, []);
  },
  async GET_ONBOARDING_FLOW_TEMPLATES({ commit }, spProductId) {
    try {
      const response = await axios.get(
        ONBOARDING_FLOW_TEMPLATES_ENDPOINT.replace(':spProductId', spProductId)
      );
      commit(SET_ONBOARDING_FLOW_TEMPLATES, response.data.data);
    } catch (error) {
      throw 'Error while getting the Onboarding Flow Templates. Try again later.';
    }
  },
  CLEAR_ONBOARDING_FLOW_TEMPLATES({ commit }) {
    commit(SET_ONBOARDING_FLOW_TEMPLATES, []);
  },
  async UPDATE_PRODUCT_WEBHOOK({ commit, state }, { spProductId, webhook }) {
    try {
      const response = await axios.put(
        `${SINGLE_SP_PRODUCT_ENDPOINT.replace(':spProductId', spProductId)}/webhook`,
        { webhook }
      );
      commit(SET_WEBHOOKS, { ...state.webhooks, [spProductId]: webhook });
      return response.data;
    } catch (error) {
      throw 'Error while updating the product webhook. Try again later.';
    }
  },
  async GET_PRODUCTS_WEBHOOKS({ commit }) {
    try {
      const response = await axios.get(`${SP_PRODUCTS_ENDPOINT}/webhooks`);
      const webhooks = response.data.data;
      commit(SET_WEBHOOKS, webhooks);
      return webhooks;
    } catch (error) {
      throw new Error('Error while getting the Product Webhooks. Try again later.');
    }
  },
  async UPDATE_PRODUCT_BRANDING({ commit, state }, { spProductId, branding }) {
    console.log(`Updating branding for product ${spProductId}`, branding);
    try {
      const response = await axios.put(
        BRANDING_ENDPOINT.replace(':spProductId', spProductId),
        { branding }
      );
      console.log(`Branding update response:`, response.data);
      commit(SET_BRANDING, { spProductId, branding });
      return response.data;
    } catch (error) {
      console.error(`Error updating branding for product ${spProductId}:`, error);
      throw 'Error while updating the product branding. Try again later.';
    }
  },
  CLEAR_PRODUCTS({ commit }) {
    commit(CLEAR_PRODUCTS);
  }
};

const getters = {
  getFeesTemplates: state => state.feesTemplates,
  getOnboardingFlowTemplates: state => state.onboardingFlowTemplates,
  getProductsWebhooks: state => state.webhooks,
  getSpProducts: state => state.products
};

const mutations = {
  [SET_FEES_TEMPLATES](state, data) {
    state.feesTemplates = data;
  },
  [SET_ONBOARDING_FLOW_TEMPLATES](state, data) {
    state.onboardingFlowTemplates = data;
  },
  [SET_WEBHOOKS](state, webhooks) {
    state.webhooks = webhooks;
  },
  [SET_PRODUCTS](state, products) {
    state.products = products;
  },
  [SET_BRANDING](state, { spProductId, branding }) {
    if (state.products && state.products.data && Array.isArray(state.products.data)) {
      state.products.data = state.products.data.map(product =>
        product.spProductId === spProductId ? { ...product, branding } : product
      );
    } else {
      console.error('state.products.data is not an array:', state.products);
    }
  },
  [CLEAR_PRODUCTS](state) {
    state.products = [];
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
