import axios from 'axios';
import ResponseParser from './ResponseParser';

function getTransactionEndpoint(transactionId) {
  return `/backoffice/transactions/${transactionId}`;
}

function getCreditCardTransactionEndpoint(transactionId) {
  return `/backoffice/transactions/creditcard/${transactionId}`;
}

// Mutation types
const SET_CREDIT_CARD_TOKEN = 'transactions/SET_CREDIT_CARD_TOKEN';

// initial state
const state = {
  creditCardToken: null
};

// actions
const actions = {
  TRANSACTIONS_CAPTURE(context, params) {
    let endpoint = `${getCreditCardTransactionEndpoint(params.id)}/capture`;
    const data = {
      amount: params.amount,
      subMerchantId: params.subMerchantId,
      description: params.description,
      customerId: params.customerId,
      billing: params.billing
    };
    return new Promise((resolve, reject) => {
      axios
        .post(endpoint, data)
        .then(() => resolve())
        .catch(error =>
          reject(new ResponseParser(error, 'capture').getResponse())
        );
    });
  },
  TRANSACTION_UPDATE_STATUS(context, params) {
    let endpoint = `/backoffice/transactions/status/${params.id}`;
    const data = {
      status: params.status
    };
    return new Promise((resolve, reject) => {
      axios
        .put(endpoint, data)
        .then(() => resolve())
        .catch(error =>
          reject(new ResponseParser(error, 'update status').getResponse())
        );
    });
  },
  TRANSACTIONS_VOID(context, params) {
    let endpoint = `${getCreditCardTransactionEndpoint(params.id)}/void`;
    const data = {
      subMerchantId: params.subMerchantId,
      description: params.description,
      customerId: params.customerId
    };
    return new Promise((resolve, reject) => {
      axios
        .post(endpoint, data)
        .then(() => resolve())
        .catch(error =>
          reject(new ResponseParser(error, 'void').getResponse())
        );
    });
  },
  TRANSACTIONS_REFUND(context, params) {
    let endpoint = `${getCreditCardTransactionEndpoint(params.id)}/refund`;
    const data = {
      amount: params.amount,
      subMerchantId: params.subMerchantId,
      description: params.description,
      customerId: params.customerId,
      billing: params.billing
    };
    return new Promise((resolve, reject) => {
      axios
        .post(endpoint, data)
        .then(() => resolve())
        .catch(error =>
          reject(new ResponseParser(error, 'refund').getResponse())
        );
    });
  },
  TRANSACTIONS_CHARGE(context, data) {
    let endpoint = '/backoffice/transactions/creditcard/sale';
    return new Promise((resolve, reject) => {
      axios
        .post(endpoint, { ...data })
        .then(response => resolve(response.data))
        .catch(error =>
          reject(new ResponseParser(error, 'charge').getResponse())
        );
    });
  },
  EDIT_TRANSACTION(context, params) {
    let data = { ...params };
    delete data.id;
    const endpoint = `${getTransactionEndpoint(params.id)}`;
    return new Promise((resolve, reject) => {
      axios
        .put(endpoint, data)
        .then(response => resolve(response.data))
        .catch(() => {
          reject('Error while editing the transaction. Try again later.');
        });
    });
  },
  EDIT_TRANSACTIONS_CUSTOMERS(context, params) {
    let data = { ...params };
    delete data.id;
    const endpoint = `${getTransactionEndpoint(params.id)}/customers`;
    return new Promise((resolve, reject) => {
      axios
        .put(endpoint, data)
        .then(response => resolve(response.data))
        .catch(() => {
          reject('Error while editing the transaction. Try again later.');
        });
    });
  }
};

// getters
const getters = {
  getCreditCardToken: state => state.creditCardToken
};

// mutations
const mutations = {
  [SET_CREDIT_CARD_TOKEN](state, data) {
    state.creditCardToken = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
