import { Input, InputNumber, Tooltip, Popover } from 'element-ui';
import BaseRadio from '@commons/components/Inputs/BaseRadio';
import BaseInput from '@commons/components/Inputs/BaseInput.vue';
import BaseDropdown from '@commons/components/BaseDropdown.vue';
import Card from '@commons/components/Cards/Card.vue';
import BaseButton from '@commons/components/BaseButton.vue';
import BaseColorInput from '@commons/components/BaseColorInput.vue';
import BaseCheckbox from '@commons/components/Inputs/BaseCheckbox.vue';
import BaseCustomInput from '@commons/components/Inputs/BaseCustomInput.vue';
import BaseMaskedInput from '@commons/components/Inputs/BaseMaskedInput.vue';
import BaseNumericInput from '@commons/components/Inputs/BaseNumericInput';
import BaseDatePicker from '@commons/components/Inputs/BaseDatePicker';
import BaseSelect from '@commons/components/Inputs/BaseSelect';
import BaseSwitch from '@commons/components/BaseSwitch';
import BasePhoneInput from '@commons/components/Inputs/BasePhoneInput';
import TooltipUUIDCombo from '@commons/components/TooltipUUIDCombo';

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(BaseCustomInput.name, BaseCustomInput);
    Vue.component(BaseMaskedInput.name, BaseMaskedInput);
    Vue.component(BaseNumericInput.name, BaseNumericInput);
    Vue.component(BaseDatePicker.name, BaseDatePicker);
    Vue.component(BaseSelect.name, BaseSelect);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(Card.name, Card);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseColorInput.name, BaseColorInput);
    Vue.component(Input.name, Input);
    Vue.component(InputNumber.name, InputNumber);
    Vue.component(BaseSwitch.name, BaseSwitch);
    Vue.use(Tooltip);
    Vue.use(Popover);
    Vue.component(BasePhoneInput.name, BasePhoneInput);
    Vue.component(TooltipUUIDCombo.name, TooltipUUIDCombo);
  }
};

export default GlobalComponents;
