import { DateTime } from 'luxon';
import {
  DB_COMPLETE_DATE_FORMAT,
  DB_DATETIME_FORMAT_HH_MM_SS,
  PRETTY_DATETIME_FORMAT,
  PRETTY_DATE_FORMAT
} from '@commons/data/constants';

export const datetimeToString = datetime => {
  return DateTime.fromJSDate(datetime).toFormat(DB_DATETIME_FORMAT_HH_MM_SS);
};

export const datetimeToUTCString = datetime => {
  if (DateTime.isDateTime(datetime))
    return datetime.toUTC().toFormat(DB_DATETIME_FORMAT_HH_MM_SS);
  else return DateTime.fromJSDate(datetime).toUTC().toFormat(DB_DATETIME_FORMAT_HH_MM_SS);
};

export const formatDatetimeToDisplay = datetimeString => {
  return DateTime.fromISO(datetimeString).toFormat(PRETTY_DATETIME_FORMAT);
};
/**
 * Formats a human-readable pretty date into a databas date format (yyyy-MM-dd).
 * @param {string} date - The date string to be formatted.
 * @returns {string} The formatted database-compatible date string.
 */
export const formatPrettyDateToDBDate = date => {
  return DateTime.fromFormat(date, PRETTY_DATE_FORMAT).toFormat(DB_COMPLETE_DATE_FORMAT);
};
/**
 * Formats a date (yyyy-MM-dd) into a human-readable pretty date.
 * @param {string} date - The date string to be formatted.
 * @returns {string} The formatted pretty date string.
 */
export const formatDBDateToPrettyDate = date => {
  return DateTime.fromFormat(date, DB_COMPLETE_DATE_FORMAT).toFormat(PRETTY_DATE_FORMAT);
};

export const formatDateSelectors = object => {
  if (typeof object !== 'object' || object === null) {
    return object;
  }
  const result = {};
  for (const key in object) {
    const value = object[key];

    if (
      DateTime.isDateTime(value) ||
      value instanceof Date ||
      (typeof value === 'string' && DateTime.fromISO(value).isValid && !value.match(/^\d+$/))
    ) {
      const dateTimeValue =
        value instanceof Date
          ? DateTime.fromJSDate(value)
          : typeof value === 'string'
          ? DateTime.fromISO(value)
          : value;
      if (key === 'from' || key === 'to') {
        const adjustedDateTimeValue =
          key === 'from' ? dateTimeValue.startOf('day') : dateTimeValue.endOf('day');
        result[key] = `"${datetimeToUTCString(adjustedDateTimeValue)}"`;
      } else {
        result[key] = {
          from: `"${datetimeToUTCString(dateTimeValue.startOf('day'))}"`,
          to: `"${datetimeToUTCString(dateTimeValue.endOf('day'))}"`
        };
      }
    } else if (Array.isArray(value)) {
      result[key] = value.map(el =>
        typeof el === 'string' ? el : formatDateSelectors(el)
      );
    } else if (typeof value === 'object' && value !== null) {
      result[key] = formatDateSelectors(value);
    } else {
      result[key] = value;
    }
  }
  return result;
};
