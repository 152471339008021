import Vue from 'vue';
import Vuex from 'vuex';
// Modules
import config from '@/config';
import { ENVIRONMENTS } from '@commons/data/constants';
import submerchants from './submerchants';
import onboardingInvites from './onboardingInvites';
import apiKeys from './apiKeys';
import spProducts from './spProducts';
// import reporting from './reporting'
Vue.use(Vuex);

const debug = config.ENV !== ENVIRONMENTS.PROD;

// this has to be a simple object if it will be used in the index store as a module, otherwise make it a store
export default {
  namespaced: true,
  modules: {
    submerchants,
    onboardingInvites,
    apiKeys,
    spProducts
    // reporting
  },
  strict: debug
};
