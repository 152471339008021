import axios from 'axios';
import qs from 'qs';

const TOKENS_ENDPOINT = 'backoffice/tokens';

const CC_TOKENIZATION_ENDPOINT = 'backoffice/creditcard/tokenization/';
const TOKENIZATION_ENDPOINT = 'backoffice/tokenization/';
const TOKENS_VIRTUAL_TERMINAL_ENDPOINT = `${TOKENS_ENDPOINT}/virtualTerminal`;

const MICROFORM_GET_CONTEXT_ENDPOINT = `${CC_TOKENIZATION_ENDPOINT}:subMerchantId/generatecontext`;
const MICROFORM_EXECUTE_TOKENIZATION_ENDPOINT = `${TOKENIZATION_ENDPOINT}tokenize/microform`;

const SET_TOKENS_VIRTUAL_TERMINAL = 'tokens/SET_TOKENS_VIRTUAL_TERMINAL';

// initial state
const state = { tokenVirtualTerminal: {} };

// actions
const actions = {
  GET_TOKEN_SESSION_ID(context, publicKey) {
    return new Promise((resolve, reject) => {
      axios
        .get(CC_TOKENIZATION_ENDPOINT + publicKey + '/session')
        .then(response => resolve({ data: response.data.data }))
        .catch(error => reject(error));
    });
  },
  AUTHORIZE_SESSION(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(TOKENIZATION_ENDPOINT + 'session/authorize', data)
        .then(response => resolve({ data: response.data.data }))
        .catch(error => reject(error));
    });
  },
  GET_TOKENS_VIRTUAL_TERMINAL({ commit }, { filters, abortSignal }) {
    const params = filters;
    return new Promise((resolve, reject) => {
      axios
        .get(TOKENS_VIRTUAL_TERMINAL_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true }),
          cancelPreviousRequest: true
        })
        .then(response => {
          commit(SET_TOKENS_VIRTUAL_TERMINAL, {
            count: response.data.count,
            data: response.data.data
          });
          resolve({ data: response.data });
        })
        .catch(error => reject(error));
    });
  },
  CLEAR_TOKENS_VIRTUAL_TERMINAL({ commit }) {
    commit(SET_TOKENS_VIRTUAL_TERMINAL, {});
  },
  async GET_MICROFORM_CONTEXT({ commit }, data) {
    try {
      const url = MICROFORM_GET_CONTEXT_ENDPOINT.replace(
        ':subMerchantId',
        data.subMerchantId
      );
      const generateContextResponse = await axios.post(url, {
        targetOrigins: data.targetOrigins
      });
      const keyId = generateContextResponse.data.data.keyId;
      return keyId;
    } catch (error) {
      console.log(`Error getting microform context: ${error}`);
      throw error;
    }
  },
  async EXECUTE_MICROFORM_TOKENIZATION({ commit }, data) {
    try {
      const requestPayload = {
        transientToken: data.transientToken,
        cardHolderName: data.cardHolderName,
        tokenSessionId: data.tokenSessionId,
        publicKey: data.publicKey
      };
      const url = MICROFORM_EXECUTE_TOKENIZATION_ENDPOINT.replace(
        ':subMerchantId',
        data.subMerchantId
      );
      const executeTokenizationResponse = await axios.post(url, requestPayload);
      if (executeTokenizationResponse.status === 200) {
        return executeTokenizationResponse.data.data;
      }
    } catch (error) {
      console.log(`Error getting microform context: ${error}`);
      throw error;
    }
  }
};

const getters = { getTokensVirtualTerminal: state => state.tokenVirtualTerminal };

const mutations = {
  [SET_TOKENS_VIRTUAL_TERMINAL](state, data) {
    state.tokenVirtualTerminal = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
