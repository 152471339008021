import axios from 'axios';
import { formatDateSelectors } from '../helpers/date';

const CHARTS_ENDPOINT = '/reporting/transactions/charts';

const SET_ACCOUNTS = 'charts/SET_ACCOUNTS';
const SET_TRANSACTIONS_VOLUME = 'charts/SET_TRANSACTIONS_VOLUME';
const SET_TRANSACTIONS_COUNTER = 'charts/SET_TRANSACTIONS_COUNTER';
const SET_PAYOUTS = 'charts/SET_PAYOUTS';

function getChartSeriesByName(chartData, seriesNames) {
  return chartData.series.filter(serie => seriesNames.includes(serie.name));
}

const state = {
  accounts: {},
  transactionsVolume: {},
  transactionsCounter: {},
  payouts: {}
};

// actions
const actions = {
  async CHARTS_ACCOUNTS({ commit }, data) {
    const params = formatDateSelectors(data);
    if (
      params.subMerchantId &&
      params.subMerchantId.length &&
      !Array.isArray(params.subMerchantId)
    ) {
      params.subMerchantId = `"${params.subMerchantId.join(',')}"`;
    }
    const endpoint = `${CHARTS_ENDPOINT}/daily`;

    try {
      const response = await axios.get(endpoint, { params });
      commit(SET_ACCOUNTS, response.data.chartData);
    } catch (error) {
      throw new Error('CHARTS_ACCOUNTS_ERROR_MESSAGE');
    }
  },

  CLEAR_CHART_ACCOUNTS({ commit }) {
    commit(SET_ACCOUNTS, {});
  },

  async CHART_TRANSACTIONS_VOLUME({ commit }, filter) {
    const params = formatDateSelectors(filter);
    if (filter.subMerchantId?.length) {
      params.subMerchantId = filter.subMerchantId.join(',');
    }
    if (filter['processing.status']) {
      params['processing.status'] = filter['processing.status'];
    }
    delete params.period;
    if (!filter.period) {
      filter.period = 'daily';
    }
    const endpoint = `${CHARTS_ENDPOINT}/${filter.period}`;

    try {
      const response = await axios.get(endpoint, { params });
      const data = response.data.chartData;
      data.series = getChartSeriesByName(data, ['Amount']);
      commit(SET_TRANSACTIONS_VOLUME, data);
    } catch (error) {
      throw new Error('CHARTS_PURCHASES_ERROR_MESSAGE');
    }
  },

  CLEAR_TRANSACTIONS_VOLUME({ commit }) {
    commit(SET_TRANSACTIONS_VOLUME, {});
  },

  async CHART_TRANSACTIONS_COUNTER({ commit }, filter) {
    const params = formatDateSelectors(filter);
    if (filter.subMerchantId?.length) {
      params.subMerchantId = filter.subMerchantId.join(',');
    }
    delete params.period;
    const endpoint = `${CHARTS_ENDPOINT}/${filter.period}`;

    try {
      const response = await axios.get(endpoint, { params });
      const data = response.data.chartData;
      data.series = getChartSeriesByName(data, ['Count']);
      commit(SET_TRANSACTIONS_COUNTER, data);
    } catch (error) {
      throw new Error('CHARTS_TRANSACTIONS_ERROR_MESSAGE');
    }
  },

  CLEAR_TRANSACTIONS_COUNTER({ commit }) {
    commit(SET_TRANSACTIONS_COUNTER, {});
  },

  async CHART_PAYOUTS({ commit }, filter) {
    const params = formatDateSelectors(filter);
    if (filter.subMerchantId?.length) {
      params.subMerchantId = filter.subMerchantId.join(',');
    }
    delete params.period;
    const endpoint = `/reporting/payouts/charts/${filter.period}`;

    try {
      const response = await axios.get(endpoint, { params });
      commit(SET_PAYOUTS, response.data.chartData);
    } catch (error) {
      throw new Error('CHARTS_PAYOUTS_ERROR_MESSAGE');
    }
  },

  CLEAR_CHART_PAYOUTS({ commit }) {
    commit(SET_PAYOUTS, {});
  }
};

// getters
const getters = {
  getAccountsChartData: state => state.accounts,
  getTransactionsVolumeChartData: state => state.transactionsVolume,
  getTransactionsCounterChartData: state => state.transactionsCounter,
  getPayoutsChartData: state => state.payouts
};

// mutations
const mutations = {
  [SET_ACCOUNTS](state, data) {
    state.accounts = data;
  },
  [SET_TRANSACTIONS_VOLUME](state, data) {
    state.transactionsVolume = data;
  },
  [SET_TRANSACTIONS_COUNTER](state, data) {
    state.transactionsCounter = data;
  },
  [SET_PAYOUTS](state, data) {
    state.payouts = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};