import axios from 'axios';
import qs from 'qs';
import { formatDateSelectors } from '../helpers/date';
import { arrayBufferToJson } from '../helpers/utils';

const BASE_URL = 'foundation';
const BASE_USERACTIVITIES_ENDPOINT = `${BASE_URL}/useractivities`;
const BASE_WEBHOOKS_ENDPOINT = `${BASE_URL}/webhooks`;
const BASE_TAGS_ENDPOINT = `${BASE_URL}/tags`;
const BASE_COMMENTS_ENDPOINT = `${BASE_URL}/comments`;
const NOTIFICATIONS_ENDPOINT = `${BASE_URL}/notifications`;
const ALERTS_ENDPOINT = `${BASE_URL}/alerts`;

// MUTATIONS
const SET_NOTIFICATIONS = 'FOUNDATION/SET_NOTIFICATIONS';
const SET_API_LOGS = `${BASE_URL}/apilogs/SET_API_LOGS`;
const SET_API_LOGS_BY_ID = `${BASE_URL}/apilogs/SET_API_LOGS_BY_ID`;
const SET_WEBHOOKS = `${BASE_URL}/webhooks/SET_WEBHOOKS`;
const SET_TAGS = `${BASE_URL}/tags/SET_TAGS`;
const SET_TAG_DEFINITIONS = `${BASE_URL}/tags/SET_TAG_DEFINITIONS`;
const ADD_TAG_DEFINITION = `${BASE_URL}/tags/ADD_TAG_DEFINITION`;
const UPDATE_TAG_DEFINITION = `${BASE_URL}/tags/UPDATE_TAG_DEFINITION`;
const SET_RAWS = `${BASE_URL}/raws`;
const GET_COMMENTS = 'COMMENTS/GET_COMMENTS';
const GET_API_LOGS_ENDPOINT = `${BASE_URL}/developer/apilogs`;
const GET_RAWS_ENDPOINT = `${BASE_URL}/raws/get`;
const SET_NOTIFICATION_EVENTS = 'FOUNDATION/SET_NOTIFICATION_EVENTS';
const CLEAR_NOTIFICATION_EVENTS = 'FOUNDATION/CLEAR_NOTIFICATION_EVENTS';
const SET_ACKNOWLEDGED_AT = 'FOUNDATION/SET_ACKNOWLEDGED_AT';
const SET_ALERTS = `${BASE_URL}/notifications/SET_ALERTS`;
const DISCARD_ALERT = `${BASE_URL}/notifications/DISCARD_ALERT`;
const ACK_ALERT = `${BASE_URL}/notifications/ACK_ALERT`;
const UNACK_ALERT = `${BASE_URL}/notifications/UNACK_ALERT`;

const SET_SUCCESS_LOGS = 'charts/SET_SUCCESS_LOGS';
const SET_ERROR_LOGS = 'charts/SET_ERROR_LOGS';
const SET_RESPONSE_TIME = 'charts/SET_RESPONSE_TIME';

const ENDPOINTS = {
  GET_USER_ACTIVITIES: `${BASE_USERACTIVITIES_ENDPOINT}/get`,
  GET_WEBHOOKS: `${BASE_WEBHOOKS_ENDPOINT}/get`,
  GET_TAGS: `${BASE_TAGS_ENDPOINT}/get`,
  CREATE_TAG: `${BASE_TAGS_ENDPOINT}/create`,
  REMOVE_TAG: `${BASE_TAGS_ENDPOINT}/remove`,
  GET_TAG_DEFINITIONS: `${BASE_TAGS_ENDPOINT}/definitions/get`,
  CREATE_TAG_DEFINITION: `${BASE_TAGS_ENDPOINT}/definitions/create`,
  UPDATE_TAG_DEFINITION: `${BASE_TAGS_ENDPOINT}/definitions/update`,
  SET_COMMENT: `${BASE_COMMENTS_ENDPOINT}/set`,
  GET_COMMENTS: `${BASE_COMMENTS_ENDPOINT}/get`,
  GET_NOTIFICATIONS: `${NOTIFICATIONS_ENDPOINT}/get`,
  ACK_NOTIFICATIONS: `${NOTIFICATIONS_ENDPOINT}/ack`,
  GET_NOTIFICATION_EVENTS: `${NOTIFICATIONS_ENDPOINT}/events/get`,
  GET_ALERTS: `${ALERTS_ENDPOINT}/get`,
  DISCARD_ALERTS: `${ALERTS_ENDPOINT}/discard`,
  ACK_ALERTS: `${ALERTS_ENDPOINT}/ack`,
  UNACK_ALERTS: `${ALERTS_ENDPOINT}/unack`,
  GET_SUCCESS_API_LOGS: `${BASE_URL}/developer/apilogs/success`,
  GET_ERROR_API_LOGS: `${BASE_URL}/developer/apilogs/error`,
  GET_RESPONSE_TIME_API_LOGS: `${BASE_URL}/developer/apilogs/responsetime`
};

function initialState() {
  return {
    notifications: [],
    alerts: [],
    comments: [],
    apiLogs: {},
    apiLogsById: {},
    webhooks: {},
    raws: {},
    tags: [],
    tagDefinitions: [],
    notificationEvents: {},
    successApiLogs: {},
    errorApiLogs: {},
    apiLogsResponseTime: {},
  };
}
// initial state
const state = initialState();

// actions
const actions = {
  async GET_USER_ACTIVITIES({ commit }, params) {
    params = formatDateSelectors(params);
    const results = await axios.post(ENDPOINTS.GET_USER_ACTIVITIES, {
      params,
      paramsSerializer: params => qs.stringify(params, { allowDots: false })
    });
    const data = [];
    results.data.data.forEach((c, i) => {
      data[i] = {
        ...c
      };
      data[i].roles = [];
      data[i].roles = data[i].roles.join(', ');
    });
    return { count: results.data.count, data };
  },
  async GET_WEBHOOKS({ commit }, params) {
    params = formatDateSelectors(params);
    try {
      const results = await axios.post(ENDPOINTS.GET_WEBHOOKS, {
        params,
        paramsSerializer: params => qs.stringify(params, { allowDots: false })
      });
      // Adjusting the format of the headers within the request and response objects to match the specified format
      results.data.data = results.data.data.map(item => {
        // Formatting request headers
        if (item.request?.headers) {
          item.request.headers = Object.entries(item.request.headers).map(
            ([key, value]) => `${key}: ${value}`
          );
        }
        // Formatting response headers
        if (item.response?.headers) {
          item.response.headers = Object.entries(item.response.headers).map(
            ([key, value]) => `${key}: ${value}`
          );
        }
        return item;
      });
      commit(SET_WEBHOOKS, results.data);
    } catch (error) {
      if (axios.isCancel(error)) return;
      else
        throw (
          error.message ||
          'Something went wrong fetching transactions. Please try again later.'
        );
    }
  },
  async GET_UA_COMPARISON({ commit }, params) {
    try {
      const results = await axios.post(ENDPOINTS.GET_USER_ACTIVITIES, {
        params,
        paramsSerializer: params => qs.stringify(params, { allowDots: true })
      });
      const data = [];
      results.data.data.forEach((c, i) => {
        data[i] = {
          ...c
        };
        data[i].roles = [];
        data[i].roles = data[i].roles.join(', ');
      });
      if (results.data.count > 1) {
        return { data: data };
      }
    } catch (error) {
      console.log(error);
    }
  },
  DOWNLOAD_USERS_ACTIVITIES(context, data) {
    const params = formatDateSelectors(data);
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.GET_USER_ACTIVITIES, {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/csv'
          },
          params: { ...params, export: 'csv' },
          paramsSerializer: params =>
            qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: 'octet/stream'
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'users-activities.csv');
          document.body.appendChild(link);
          link.click();
          resolve();
        })
        .catch(() => reject('DOWNLOAD_USER_ACTIVITIES_ERROR_MESSAGE'));
    });
  },
  async ADD_COMMENT(context, data) {
    try {
      return await axios.post(ENDPOINTS.SET_COMMENT, data);
    } catch (error) {
      throw 'Unable to add comment, try again later.';
    }
  },
  async GET_COMMENTS({ commit }, params) {
    try {
      const results = await axios.post(ENDPOINTS.GET_COMMENTS, {
        params,
        paramsSerializer: params => qs.stringify(params, { allowDots: true })
      });
      if (results) {
        commit(GET_COMMENTS, results.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  CLEAR_COMMENTS({ commit }) {
    commit(GET_COMMENTS, {});
  },
  async GET_TAGS({ commit }, params) {
    try {
      const results = await axios.post(
        ENDPOINTS.GET_TAGS,
        { params, paramsSerializer: params => qs.stringify(params, { allowDots: true }) },
        { cancelPreviousRequests: true }
      );
      if (results) {
        commit(SET_TAGS, results.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async ADD_TAG({ commit }, data) {
    try {
      const results = await axios.post(ENDPOINTS.CREATE_TAG, data);
      return results;
    } catch (error) {
      console.log(error);
    }
  },
  async REMOVE_TAG({ commit }, data) {
    try {
      const results = await axios.post(ENDPOINTS.REMOVE_TAG, data);
      return results;
    } catch (error) {
      console.log(error);
    }
  },
  CLEAR_TAGS({ commit }) {
    commit(SET_TAGS, {});
  },
  async GET_TAG_DEFINITIONS({ commit }, params) {
    try {
      const results = await axios.post(
        ENDPOINTS.GET_TAG_DEFINITIONS,
        { params, paramsSerializer: params => qs.stringify(params, { allowDots: true }) },
        { cancelPreviousRequests: true }
      );
      commit(SET_TAG_DEFINITIONS, results.data);
    } catch (error) {
      console.log(error);
    }
  },
  async ADD_TAG_DEFINITION({ commit }, data) {
    try {
      const results = await axios.post(ENDPOINTS.CREATE_TAG_DEFINITION, data);
      commit(ADD_TAG_DEFINITION, results.data);
      return results;
    } catch (error) {
      console.log(error);
    }
  },
  async UPDATE_TAG_DEFINITION({ commit }, data) {
    try {
      const results = await axios.post(ENDPOINTS.UPDATE_TAG_DEFINITION, data);
      commit(UPDATE_TAG_DEFINITION, results.data);
      return results;
    } catch (error) {
      console.log(error);
    }
  },

  async GET_NOTIFICATION_EVENTS({ commit }, data) {
    const params = formatDateSelectors(data);
    try {
      const response = await axios.post(ENDPOINTS.GET_NOTIFICATION_EVENTS, {
        params,
        paramsSerializer: params => qs.stringify(params, { indices: false })
      });
      commit(SET_NOTIFICATION_EVENTS, {
        count: response.data.count,
        data: response.data.data
      });
    } catch (error) {
      console.error(error);
    }
  },

  async GET_DASHBOARD_NOTIFICATION_EVENTS({ commit }, params) {
    params = { ...params, isDashboard: true };
    const response = await axios.post(ENDPOINTS.GET_NOTIFICATION_EVENTS, {
      params,
      paramsSerializer: params => qs.stringify(params, { indices: false })
    });
    commit(SET_NOTIFICATION_EVENTS, {
      count: response.data.count,
      data: response.data.data
    });
  },

  CLEAR_NOTIFICATION_EVENTS({ commit }) {
    commit(CLEAR_NOTIFICATION_EVENTS);
  },

  async GET_USER_NOTIFICATIONS({ commit }) {
    try {
      const response = await axios.post(ENDPOINTS.GET_NOTIFICATIONS);
      const notifications = response.data;
      commit(SET_NOTIFICATIONS, notifications);
      return notifications;
    } catch (error) {
      throw error.response;
    }
  },
  async ACK_NOTIFICATION({ commit }, data) {
    try {
      const response = await axios.post(ENDPOINTS.ACK_NOTIFICATIONS, data);
      if (response.data.success) {
        commit(SET_ACKNOWLEDGED_AT, {
          notificationId: data.notificationEventId,
          timestamp: new Date().toISOString()
        });
      } else {
        throw new Error('Acknowledgment failed');
      }
    } catch (error) {
      console.error('Error acknowledging notification:', error);
      throw error;
    }
  },
  async GET_API_LOGS({ commit }, data) {
    const params = formatDateSelectors(data);
    try {
      const response = await axios.get(GET_API_LOGS_ENDPOINT, {
        params,
        paramsSerializer: params => qs.stringify(params, { allowDots: true }),
        cancelPreviousRequests: true
      });
      commit(SET_API_LOGS, response.data);
      return response;
    } catch (error) {
      if (axios.isCancel(error)) return;
      else
        throw (
          error.message ||
          'Something went wrong fetching transactions. Please try again later.'
        );
    }
  },
  async GET_API_LOGS_BY_ID({ commit }, data) {
    const params = formatDateSelectors(data);
    try {
      const response = await axios.get(GET_API_LOGS_ENDPOINT, {
        params,
        paramsSerializer: params => qs.stringify(params, { allowDots: true })
      });
      commit(SET_API_LOGS_BY_ID, response.data);
      return response;
    } catch (error) {
      if (axios.isCancel(error)) return;
      else
        throw (
          error.message ||
          'Something went wrong fetching transactions. Please try again later.'
        );
    }
  },
  async GET_RAWS({ commit }, data) {
    const params = formatDateSelectors(data);
    try {
      const response = await axios.get(GET_RAWS_ENDPOINT, {
        params,
        paramsSerializer: params => qs.stringify(params, { allowDots: true }),
        cancelPreviousRequests: true
      });
      commit(SET_RAWS, response.data);
      return response;
    } catch (error) {
      console.error(error);
    }
  },
  async GET_ALERTS({ commit }, data) {
    const params = formatDateSelectors(data);
    try {
      const response = await axios.post(
        ENDPOINTS.GET_ALERTS,
        { params, paramsSerializer: params => qs.stringify(params, { indices: false }) },
        { cancelPreviousRequests: true }
      );
      if (response.data.success) {
        commit(SET_ALERTS, {
          count: response.data.count,
          data: response.data.data
        });
      }
    } catch (error) {
      console.error(error);
    }
  },
  async DISCARD_ALERT({ commit }, data) {
    try {
      const response = await axios.post(ENDPOINTS.DISCARD_ALERTS, data);
      if (response.data.success) {
        commit(DISCARD_ALERT, {
          alertId: response.data.data.alertId
        });
        return response;
      }
      throw 'Failed to discard alert.';
    } catch (error) {
      console.error(error);
    }
  },
  async ACK_ALERT({ commit }, data) {
    try {
      const response = await axios.post(ENDPOINTS.ACK_ALERTS, data);
      if (response.data.success) {
        commit(ACK_ALERT, {
          alertId: response.data.data.alertId
        });
        return response.data;
      }
      throw new Error(response.data.message || 'Failed to acknowledge alert.');
    } catch (error) {
      console.error('Error acknowledging alert:', error);
      throw error;
    }
  },
  async UNACK_ALERT({ commit }, data) {
    try {
      const response = await axios.post(ENDPOINTS.UNACK_ALERTS, data);
      if (response.data.success) {
        commit(UNACK_ALERT, {
          alertId: response.data.data.alertId,
          timestamp: new Date().toISOString(),
        });
        return response;
      }
      throw 'Failed to un-acknowledge alert.';
    } catch (error) {
      console.error('Error un-acknowledging alert:', error);
      throw error;
    }
  },
  async DOWNLOAD_ALERTS(context, data) {
    const fileName = data.fileName || 'alerts';
    delete data.fileName;
    try {
      const params = formatDateSelectors(data);
      const response = await axios.post(ENDPOINTS.GET_ALERTS, {
        params: { ...params, export: 'csv' },
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/csv'
        },
        paramsSerializer: params => qs.stringify(params, { indices: false })
      });
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'octet/stream' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.csv`);
      document.body.appendChild(link);
      link.click();
      return;
    } catch (error) {
      const errorResponse = arrayBufferToJson(error.response.data);
      throw (
        errorResponse?.message ??
        'Something went wrong downloading alerts. Please try again later.'
      );
    }
  },
  async CHART_SUCCESS_LOGS({ commit }, filter) {
    const params = formatDateSelectors(filter);
    if (filter.subMerchantId?.length) {
      params.subMerchantId = filter.subMerchantId.join(',');
    }
    delete params.period;
    const endpoint = `${ENDPOINTS.GET_SUCCESS_API_LOGS}/charts/${filter.period}`;

    try {
      const response = await axios.get(endpoint, {
        params,
        paramsSerializer: params => qs.stringify(params, { allowDots: true }),
        cancelPreviousRequests: true
      });
      commit(SET_SUCCESS_LOGS, response.data.chartData);
    } catch (error) {
      if (axios.isCancel(error)) return;
      else
        throw (
          error.message ||
          'Something went wrong fetching transactions. Please try again later.'
        );
    }
  },
  CLEAR_CHART_SUCCESS_LOGS({ commit }) {
    commit(SET_SUCCESS_LOGS, {});
  },
  async CHART_ERROR_LOGS({ commit }, filter) {
    const params = formatDateSelectors(filter);
    if (filter.subMerchantId?.length)
      params.subMerchantId = filter.subMerchantId.join(',');
    delete params.period;
    const endpoint = `${ENDPOINTS.GET_ERROR_API_LOGS}/charts/${filter.period}`;
    try {
      const response = await axios.get(endpoint, {
        params,
        paramsSerializer: params => qs.stringify(params, { allowDots: true }),
        cancelPreviousRequests: true
      });
      commit(SET_ERROR_LOGS, response.data.chartData);
    } catch (error) {
      if (axios.isCancel(error)) return;
      else
        throw (
          error.message ||
          'Something went wrong fetching transactions. Please try again later.'
        );
    }
  },
  CLEAR_CHART_ERROR_LOGS({ commit }) {
    commit(SET_ERROR_LOGS, {});
  },
  async CHART_RESPONSE_TIME({ commit }, filter) {
    try {
      const params = formatDateSelectors(filter);
      if (filter.subMerchantId?.length)
        params.subMerchantId = filter.subMerchantId.join(',');
      delete params.period;
      const endpoint = `${ENDPOINTS.GET_RESPONSE_TIME_API_LOGS}/charts/${filter.period}`;

      const response = await axios.get(endpoint, {
        params,
        paramsSerializer: params => qs.stringify(params, { allowDots: true }),
        cancelPreviousRequests: true
      });
      commit(SET_RESPONSE_TIME, response.data.chartData);
    } catch (error) {
      if (axios.isCancel(error)) return;
      else
        throw (
          error.message ||
          'Something went wrong fetching transactions. Please try again later.'
        );
    }
  },
  CLEAR_CHART_RESPONSE_TIME({ commit }) {
    commit(SET_RESPONSE_TIME, {});
  }
};

const getters = {
  getListComments: state => state.comments,
  userNotifications: state => state.notifications,
  getApiLogs: state => state.apiLogs,
  getApiLogsById: state => state.apiLogsById,
  getWebhooks: state => state.webhooks,
  getTags: state => state.tags,
  getTagDefinitions: state => state.tagDefinitions,
  getRaws: state => state.raws,
  getNotificationEvents: state => state.notificationEvents,
  getAlerts: state => state.alerts,
  getSuccessLogsChartData: state => state.successApiLogs,
  getErrorLogsChartData: state => state.errorApiLogs,
  getResponseTimeChartData: state => state.apiLogsResponseTime,
};

// mutations
const mutations = {
  [GET_COMMENTS](state, data) {
    state.comments = data;
  },
  [SET_NOTIFICATIONS](state, notifications) {
    state.notifications = notifications;
  },
  [SET_API_LOGS](state, data) {
    state.apiLogs = data;
  },
  [SET_API_LOGS_BY_ID](state, data) {
    state.apiLogsById = data;
  },
  [SET_RAWS](state, data) {
    state.raws = data;
  },
  [SET_WEBHOOKS](state, data) {
    state.webhooks = data;
  },
  [SET_TAGS](state, data) {
    state.tags = data;
  },
  [SET_TAG_DEFINITIONS](state, data) {
    state.tagDefinitions = data.data;
  },
  [ADD_TAG_DEFINITION](state, data) {
    state.tagDefinitions.push(data);
  },
  [UPDATE_TAG_DEFINITION](state, data) {
    state.tagDefinitions = state.tagDefinitions.map(tag =>
      tag._id === data._id ? data : tag
    );
  },
  [SET_NOTIFICATION_EVENTS](state, data) {
    state.notificationEvents = data;
  },
  [CLEAR_NOTIFICATION_EVENTS](state) {
    state.notificationEvents = {};
  },
  [SET_ACKNOWLEDGED_AT](state, { notificationId, timestamp, acknowledgedBy }) {
    const index = state.notifications.findIndex(
      n => n.notificationEventId === notificationId
    );
    if (index === -1) {
      return;
    }
    const notification = state.notifications[index];
    if (notification.channels?.ui) {
      notification.channels.ui.acknowledgedAt = timestamp;
      notification.channels.ui.acknowledgedBy = acknowledgedBy;
    }
  },
  [SET_ALERTS](state, alerts) {
    state.alerts = alerts;
  },
  [DISCARD_ALERT](state, { alertId }) {
    state.alerts.data = state.alerts.data.filter(a => a.alertId !== alertId);
  },
  [ACK_ALERT](state, { alertId, timestamp, acknowledgedBy }) {
    const alert = state.alerts.data.find(a => a.alertId === alertId);
    if (!alert) {
      return;
    }
    alert.acknowledge = {
      acknowledgedAt: timestamp,
      userId: acknowledgedBy
    };
  },
  [UNACK_ALERT](state, { alertId }) {
    const alert = state.alerts.data.find(a => a.alertId === alertId);
    if (!alert) {
      return;
    }
    alert.acknowledge = {
      acknowledgedAt: null,
      userId: null
    };
  },
  [SET_SUCCESS_LOGS](state, data) {
    state.successApiLogs = data;
  },
  [SET_ERROR_LOGS](state, data) {
    state.errorApiLogs = data;
  },
  [SET_RESPONSE_TIME](state, data) {
    state.apiLogsResponseTime = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
