import axios from 'axios';

const SET_PAYMENT_LINKS = 'paymentLinks/SET_PAYMENT_LINKS';

const PAYMENT_LINK_ENDPOINT = 'backoffice/paymentlink';
const DEACTIVATE_PAYLINK_ENDPOINT = `${PAYMENT_LINK_ENDPOINT}/:id/deactivate`;
const GET_PAYLINK_ENDPOINT = `${PAYMENT_LINK_ENDPOINT}/:id`;
const TEMPS_ENDPOINT = `backoffice/temps`;
const TEMP_ENDPOINT = `${TEMPS_ENDPOINT}/:tempId`;

const state = { paymentLinks: [] };

const actions = {
  async CREATE_PAYLINK(context, data) {
    try {
      const response = await axios.post(PAYMENT_LINK_ENDPOINT, data);
      if (response.data.success) {
        return response.data;
      }
      throw new Error(response.data.message);
    } catch (e) {
      const errorMessage =
        e.response?.data?.message || 'Error while creating the Paylink. Try again later.';
      throw new Error(errorMessage);
    }
  },
  async DEACTIVATE_PAYLINK(context, data) {
    const params = {
      sessionId: data.sessionId
    };
    try {
      const response = await axios.post(
        DEACTIVATE_PAYLINK_ENDPOINT.replace(':id', params.sessionId)
      );
      if (response.data.success) {
        return response.data;
      }
      throw new Error(response.data.message);
    } catch (e) {
      const errorMessage =
        e.response?.data?.message ||
        'Error while deactivating the Paylink. Try again later.';
      throw new Error(errorMessage);
    }
  },
  async GET_PAYLINK(context, data) {
    const params = {
      subMerchantId: data.subMerchantId
    };
    try {
      const response = await axios.get(GET_PAYLINK_ENDPOINT.replace(':id', data.paymentLinkId), { params });
      if (response.data.success) {
        return response.data.data?.data;
      }
      throw new Error(response.data.message);
    } catch (e) {
      const errorMessage =
        e.response?.data?.message ||
        'Error while getting the Paylink. Try again later.';
      throw new Error(errorMessage);
    }
  },
  async ADD_TEMP_PAYLINK(context, data) {
    try {
      const response = await axios.post(TEMPS_ENDPOINT, {
        modelName: 'paymentLinks',
        data
      });
      return response.data;
    } catch (e) {
      if (e.response?.data?.message) throw e.response.data.message;
      else throw new Error('Error creating Temp');
    }
  },
  async EDIT_TEMP_PAYLINK({ commit }, data) {
    const tempData = structuredClone(data);
    delete tempData.tempId;
    try {
      const response = await axios.put(
        TEMP_ENDPOINT.replace(':tempId', data.tempId),
        tempData
      );
      return response.data;
    } catch (e) {
      if (e.response?.data?.message) throw e.response.data.message;
      else throw new Error('Error while editing the Temp. Try again later.');
    }
  },
};

const getters = {
  getPaymentLinks: state => state.paymentLinks
};

const mutations = {
  [SET_PAYMENT_LINKS](state, data) {
    state.paymentLinks = data;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};