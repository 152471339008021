import axios from 'axios';
import ResponseParser from './ResponseParser';

function getTransactionEndpoint(transactionId) {
  return `/backoffice/transactions/echeck/${transactionId}`;
}

// initial state
const state = {
  bankAccount: null
};

// actions
const actions = {
  ECHECK_VOID(context, params) {
    let endpoint = `${getTransactionEndpoint(params.id)}/void`;
    const data = {
      subMerchantId: params.subMerchantId,
      description: params.description,
      customerId: params.customerId
    };
    return new Promise((resolve, reject) => {
      axios
        .post(endpoint, data)
        .then(() => resolve())
        .catch(error =>
          reject(new ResponseParser(error, 'void').getResponse())
        );
    });
  },
  ECHECK_REFUND(context, params) {
    let endpoint = `${getTransactionEndpoint(params.id)}/refund`;
    const data = {
      amount: params.amount,
      subMerchantId: params.subMerchantId,
      description: params.description,
      customerId: params.customerId
    };
    return new Promise((resolve, reject) => {
      axios
        .post(endpoint, data)
        .then(() => resolve())
        .catch(error =>
          reject(new ResponseParser(error, 'refund').getResponse())
        );
    });
  },
  ECHECK_SALE(context, data) {
    let endpoint = '/backoffice/transactions/echeck/sale';
    return new Promise((resolve, reject) => {
      axios
        .post(endpoint, { ...data })
        .then(response => resolve(response.data))
        .catch(error =>
          reject(new ResponseParser(error, 'sale').getResponse())
        );
    });
  }
};

export default {
  actions,
  state: { ...state }
};
