import axios from 'axios';
import qs from 'qs';
import { formatDateSelectors } from '../helpers/date';


// Mutation types
const SET_TOKEN_SESSIONS = 'tokenSessions/SET_TOKEN_SESSIONS';
const SET_DEVICE_CHART_DATA = 'charts/SET_DEVICE_CHART_DATA';
const SET_OS_CHART_DATA = 'charts/SET_OS_CHART_DATA';
const SET_BROWSER_CHART_DATA = 'charts/SET_BROWSER_CHART_DATA';


const BASE_URL = '/tokenSessions';
const ENDPOINTS = {
  GET_TOKEN_SESSIONS: `${BASE_URL}`,
  GET_TOKEN_SESSIONS_DEVICE_CHART: `${BASE_URL}/charts/device`,
  GET_TOKEN_SESSIONS_BROWSER_CHART_DATA: `${BASE_URL}/charts/browser`,
  GET_TOKEN_SESSIONS_OS_CHART_DATA: `${BASE_URL}/charts/os`

}

function initialState() {
  return {
    tokenSessions: [],
    deviceChartData: {},
    osChartData: {},
    browserChartData: {}
  };
}
// initial state
const state = initialState();

// actions
const actions = {
  async GET_TOKEN_SESSIONS({ commit }, data) {
    const params = formatDateSelectors(data);
    try {
      const response = await axios.get(ENDPOINTS.GET_TOKEN_SESSIONS, {
        params,
        paramsSerializer: params => qs.stringify(params, { allowDots: true })
      });
      commit(SET_TOKEN_SESSIONS, {
        count: response.data.count,
        data: response.data.data
      });
      return response.data;

    } catch {
      throw new Error('Something went wrong fetching transactions. Please try again later.');
    }
  },
  async CHART_DEVICES({ commit }, filter) {
    const params = formatDateSelectors(filter);
    if (filter.subMerchantId?.length) {
      params.subMerchantId = filter.subMerchantId.join(',');
    }
    delete params.period;
    const endpoint = `${ENDPOINTS.GET_TOKEN_SESSIONS_DEVICE_CHART}/${filter.period}`;

    try {
      const response = await axios.get(endpoint, {
        params,
        paramsSerializer: params => qs.stringify(params, { allowDots: true }),
        cancelPreviousRequests: true
      });
      commit(SET_DEVICE_CHART_DATA, response.data.chartData);
    } catch (error) {
      if (axios.isCancel(error)) return;
      else
        throw (
          error.message ||
          'Something went wrong fetching transactions. Please try again later.'
        );
    }
  },
  CLEAR_CHART_DEVICES({ commit }) {
    commit(SET_DEVICE_CHART_DATA, []);
  },
  async CHART_BROWSER({ commit }, filter) {
    const params = formatDateSelectors(filter);
    if (filter.subMerchantId?.length) {
      params.subMerchantId = filter.subMerchantId.join(',');
    }
    delete params.period;
    const endpoint = `${ENDPOINTS.GET_TOKEN_SESSIONS_BROWSER_CHART_DATA}/${filter.period}`;

    try {
      const response = await axios.get(endpoint, {
        params,
        paramsSerializer: params => qs.stringify(params, { allowDots: true }),
        cancelPreviousRequests: true
      });
      commit(SET_BROWSER_CHART_DATA, response.data.chartData);
    } catch (error) {
      if (axios.isCancel(error)) return;
      else
        throw (
          error.message ||
          'Something went wrong fetching transactions. Please try again later.'
        );
    }
  },
  CLEAR_CHART_BROWSER({ commit }) {
    commit(SET_BROWSER_CHART_DATA, []);
  },
  async CHART_OS({ commit }, filter) {
    const params = formatDateSelectors(filter);
    if (filter.subMerchantId?.length) {
      params.subMerchantId = filter.subMerchantId.join(',');
    }
    delete params.period;
    const endpoint = `${ENDPOINTS.GET_TOKEN_SESSIONS_OS_CHART_DATA}/${filter.period}`;

    try {
      const response = await axios.get(endpoint, {
        params,
        paramsSerializer: params => qs.stringify(params, { allowDots: true }),
        cancelPreviousRequests: true
      });
      commit(SET_OS_CHART_DATA, response.data.chartData);
    } catch (error) {
      if (axios.isCancel(error)) return;
      else
        throw (
          error.message ||
          'Something went wrong fetching transactions. Please try again later.'
        );
    }
  },
  CLEAR_CHART_OS({ commit }) {
    commit(SET_OS_CHART_DATA, []);
  },

};

// getters
const getters = {
  getTokenSessions: state => state.tokenSessions,
  getDeviceChartData: state => state.deviceChartData,
  getOsChartData: state => state.osChartData,
  getBrowserChartData: state => state.browserChartData,
};

// mutations
const mutations = {
  [SET_TOKEN_SESSIONS](state, data) {
    state.tokenSessions = data;
  },
  [SET_DEVICE_CHART_DATA](state, data) {
    state.deviceChartData = data;
  },
  [SET_OS_CHART_DATA](state, data) {
    state.osChartData = data;
  },
  [SET_BROWSER_CHART_DATA](state, data) {
    state.browserChartData = data;
  },


};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};