import axios from 'axios';
import Vue from 'vue';
import config from '@/config';
import {
  COMMONS_URLS,
  ENVIRONMENTS,
  JUPITER_APPS,
  USER_ROLES
} from '@commons/data/constants';
import {
  getBrowseFingerprint,
  getBrowseInfo,
  improvedStructuredClone
} from '@commons/helpers/utils';

// Mutation types
const SSO = 'security/SSO';
const SIGN_OUT = 'security/SIGN_OUT';
const SET_PROFILE = 'user/SET_PROFILE';
const SET_USER = 'user/SET_USER';
const USER_INFO_NAME = 'userInfo';
const SET_IP = 'user/SET_IP';
const SET_JUPICO_APP = 'user/SET_JUPICO_APP';
const SET_BRANDING = 'user/SET_BRANDING';

// Errors
const CHANGE_PASSWORD_ERROR_MESSAGE = 'Sorry, change password failed.';
const CURRENT_PASSWORD_NOT_VALID_ERROR_MESSAGE = 'The current password is not valid.';
const USERNAME_ALREADY_EXISTS_ERROR_MESSAGE = 'The username already exists.';
const EMAIL_ALREADY_EXISTS_ERROR_MESSAGE = 'E-mail already used.';
const UPDATE_USER_ERROR = 'Sorry, edit profile failed';

const ENDPOINTS = {
  SSO: 'auth/sso',
  SIGN_OUT: 'auth/logout',
  GET_USER: 'users/{id}',
  CHANGE_PASSWORD: 'users/{id}/change-password',
  LOGIN_AS: 'auth/login-as',
  DEV_LOGIN: 'auth/dev-login'
};

function initialState() {
  return {
    token: null,
    user: JSON.parse(localStorage.getItem(USER_INFO_NAME)) || {},
    profile: {},
    isLoggedInAs: false,
    ip: null,
    jupicoApp: null,
    branding: null
  };
}

// initial state
const state = initialState();

function processLoginResponse(response) {
  const token = response.token;
  const user = {
    id: response.userId,
    username: response.username,
    accounts: response.applications.payments.accounts,
    name: response.name,
    acl: response.applications.payments.acl,
    theme: { ...response.applications.payments.theme },
    notifications: response.applications.payments.notifications,
    forcePasswordReset: response.forcePasswordReset,
    productIds: response.applications.payments.productIds,
    processors: response.applications.payments.processors
  };
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  localStorage.setItem(USER_INFO_NAME, JSON.stringify(user));
  return { user, token };
}

// actions
const actions = {
  async SSO({ commit, dispatch, rootGetters }) {
    return new Promise(async (resolve, reject) => {
      const browserFingerprint = await getBrowseFingerprint();
      axios
        .post(ENDPOINTS.SSO, {
          browserFingerprint: browserFingerprint.fingerprint,
          facId: rootGetters.getFacilitator.facId,
          application: JUPITER_APPS.PAYMENTS
        })
        .then(response => {
          const processedLoginResponse = processLoginResponse(response.data);
          const roles = processedLoginResponse.user?.acl.reduce(
            (acc, a) => acc + a.role,
            ''
          );
          if (roles?.includes('service-provider')) {
            dispatch('serviceProvider/GET_ACCOUNT_NAMES');
          } else if (roles?.includes('merchant')) {
            dispatch('merchant/GET_ACCOUNT_NAMES');
          } else {
            dispatch('GET_ACCOUNT_NAMES');
          }

          commit(SSO, processedLoginResponse);
          commit(SET_IP, browserFingerprint?.ip);
          commit(SET_BRANDING, response.data.branding);

          if (response.data.isLoggedInAs) commit('SET_IS_LOGGED_IN_AS', true);
          resolve(processedLoginResponse.user);
        })
        .catch(e => {
          if (e.response.data.message === 'Not Authorized: wrong api user') {
            Vue.prototype.$notify({
              message: 'User is not in the database, run populateDB',
              timeout: 7000,
              icon: 'fa fa-circle-xmark',
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'danger'
            });
          }
          reject(e.response ? e.response.data.message : undefined);
        });
    });
  },
  SIGN_OUT({ state, dispatch, getters }) {
    return new Promise(resolve => {
      if (
        !getters.getIsLoggedInAs &&
        config.ENV === ENVIRONMENTS.LOCAL &&
        config.BYPASS_COMMONS_LOGIN
      ) {
        dispatch('CLEAR_AUTH_DATA');
        resolve(true);
      } else
        axios.post(ENDPOINTS.SIGN_OUT, { token: state.token }).finally(() => {
          dispatch('CLEAR_AUTH_DATA');
          resolve(true);
        });
    });
  },
  CLEAR_AUTH_DATA({ commit }) {
    document.cookie.split(';').forEach(cookie => {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${config.BASE_DOMAIN}`;
    });
    localStorage.removeItem(USER_INFO_NAME);
    localStorage.removeItem('accountNames');
    commit(SIGN_OUT);
  },
  GET_LOGGED_USER({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.GET_USER.replace('{id}', id))
        .then(response => {
          commit(SET_PROFILE, response.data);
          resolve();
        })
        .catch(e => reject('Error trying to get the user'));
    });
  },
  CLEAR_PROFILE({ commit }) {
    commit(SET_PROFILE, {});
  },
  UPDATE_USER({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(ENDPOINTS.GET_USER.replace('{id}', data._id), data)
        .then(() => {
          commit(SET_PROFILE, data);
          resolve();
        })
        .catch(error => {
          if (error.response?.data?.message) reject(error.response.data.message);

          if (error.response) {
            const { data = {} } = error.response;
            return data.stack.includes('email')
              ? reject(EMAIL_ALREADY_EXISTS_ERROR_MESSAGE)
              : data.stack.includes('username')
              ? reject(USERNAME_ALREADY_EXISTS_ERROR_MESSAGE)
              : reject(UPDATE_USER_ERROR);
          }
        });
    });
  },
  CHANGE_PASSWORD(context, data) {
    return new Promise((resolve, reject) => {
      let user = Object.assign({}, data);
      delete user.id;
      axios
        .post(ENDPOINTS.CHANGE_PASSWORD.replace('{id}', data.id), user)
        .then(() => resolve())
        .catch(error => {
          if (error.response?.status === 401)
            reject(CURRENT_PASSWORD_NOT_VALID_ERROR_MESSAGE);
          reject(error.response?.data?.message || CHANGE_PASSWORD_ERROR_MESSAGE);
        });
    });
  },
  ACTION_SET_THEME_SETTINGS({ commit }, data) {
    const userInfo = JSON.parse(localStorage.getItem(USER_INFO_NAME));
    localStorage.setItem(
      'userInfo',
      JSON.stringify({ ...userInfo, theme: { ...userInfo.theme, ...data } })
    );
    commit('SET_THEME_SETTINGS', data);
  },
  async LOGIN_AS(context, userId) {
    try {
      const browserInfo = await getBrowseInfo();
      const response = await axios.post(ENDPOINTS.LOGIN_AS, {
        userId,
        application: JUPITER_APPS.PAYMENTS,
        browserInfo
      });
      if (
        response.data.applications.payments.acl.some(
          a =>
            a.role === USER_ROLES.ADMIN ||
            a.role === USER_ROLES.UNDERWRITER ||
            a.role === USER_ROLES.BANK
        )
      )
        window.location.href = window.location.origin;
      else window.open(config.PAYMENT_BACKOFFICE_URL, '_blank');
    } catch (error) {
      throw 'Error while logging in';
    }
  },
  async EXIT_FROM_LOGGED_IN_AS({ commit, rootGetters, dispatch }) {
    const roles = structuredClone(rootGetters.loggedInUser.acl);
    await dispatch('SIGN_OUT');
    commit('SET_IS_LOGGED_IN_AS', false);
    if (
      roles.some(
        a =>
          a.role === USER_ROLES.ADMIN ||
          a.role === USER_ROLES.UNDERWRITER ||
          a.role === USER_ROLES.BANK
      )
    )
      window.location.href = window.location.origin;
    else window.location.replace(COMMONS_URLS.LOGIN_PAYMENTS);
  },
  REMOVE_NOTIFICATION_FROM_LOGGED_IN_USER({ state, commit }, notificationId) {
    let user = improvedStructuredClone(state.user);
    const notificationIdx = user.notifications.findIndex(n => n.id === notificationId);
    if (notificationIdx > -1) {
      user.notifications.splice(notificationIdx, 1);
      localStorage.setItem(USER_INFO_NAME, JSON.stringify(user));
      commit(SET_USER, user);
    }
  },
  UPDATE_LOGGED_IN_USER_OBJECT({ commit }, newUserInfo) {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const user = {
      ...userInfo,
      ...newUserInfo
    };
    localStorage.setItem(USER_INFO_NAME, JSON.stringify(user));
    commit(SET_USER, user);
  },
  async DEV_LOGIN({ dispatch }, { username, logout }) {
    await axios.post(ENDPOINTS.DEV_LOGIN, { username, logout });
    if (logout) dispatch('CLEAR_AUTH_DATA');
  }
};

// getters
const getters = {
  isAuthenticated: state => !!state.token,
  loggedInUser: state => state.user,
  getProfileInfo: state => state.profile,
  getAccounts: state => state.user.accounts,
  getThemeSettings: state => state.user.theme,
  getIsLoggedInAs: state => state.isLoggedInAs,
  getIp: state => state.ip,
  isLoggedUserAdmin: state => state.user?.acl?.some(a => a.role === USER_ROLES.ADMIN),
  getJupicoApp: state => state.jupicoApp,
  getBranding: state => state.branding,
  getAuthToken: state => state.token,
  isLoggedUserMerchant: state =>
    state.user?.acl?.some(
      a =>
        a.role === USER_ROLES.ACCOUNTING ||
        a.role === USER_ROLES.MANAGER ||
        a.role === USER_ROLES.APPLICANT ||
        a.role === USER_ROLES.MERCHANT_ADMIN ||
        a.role === USER_ROLES.EMPLOYEE
    )
};

// mutations
const mutations = {
  [SSO](state, data) {
    const { token, user } = data;
    state.token = token;
    state.user = user;
  },
  [SIGN_OUT](state) {
    const s = initialState();
    Object.keys(s).forEach(key => {
      if (key !== 'jupicoApp') state[key] = s[key];
    });
    axios.defaults.headers.common['Authorization'] = null;
  },
  [SET_USER](state, data) {
    state.user = data;
  },
  [SET_PROFILE](state, data) {
    state.profile = data;
  },
  SET_THEME_SETTINGS(state, data) {
    state.user = {
      ...state.user,
      theme: Vue.observable({ ...state.user.theme, ...data })
    };
  },
  SET_IS_LOGGED_IN_AS(state, data) {
    state.isLoggedInAs = data;
  },
  [SET_IP](state, data) {
    state.ip = data;
  },
  [SET_JUPICO_APP](state, data) {
    state.jupicoApp = data;
  },
  [SET_BRANDING](state, data) {
    state.branding = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
