import Vue from 'vue';
import Vuex from 'vuex';
// Modules
import config from '@/config';
import { ENVIRONMENTS } from '@commons/data/constants';
import auth from './auth';
import capital from './capital';
import charts from './charts';
import contextMenu from './contextMenu';
import customers from './customers';
import eCheckTransactions from './eCheckTransactions';
import facilitators from './facilitators';
import filters from './filters';
import foundation from './foundation';
import hosted from './hosted';
import invoices from './invoices';
import paymentLinks from './paymentLinks';
import payouts from './payouts';
import reporting from './reporting';
import risk from './risk';
import spProducts from './spProducts';
import submerchants from './submerchants';
import subscriptions from './subscriptions';
import syncs from './syncs';
import tokens from './tokens';
import tokenSessions from './tokenSessions';
import transactions from './transactions';
import ui from './ui';
import users from './users';
import widgets from './widgets';

Vue.use(Vuex);

const debug = config.ENV !== ENVIRONMENTS.PROD;

export default new Vuex.Store({
  modules: {
    auth,
    filters,
    submerchants,
    reporting,
    transactions,
    charts,
    widgets,
    customers,
    users,
    risk,
    tokens,
    payouts,
    eCheckTransactions,
    ui,
    capital,
    foundation,
    contextMenu,
    facilitators,
    hosted,
    paymentLinks,
    subscriptions,
    syncs,
    invoices,
    spProducts,
    tokenSessions
  },
  strict: debug
});
