import axios from 'axios';
import { formatDateSelectors } from '../helpers/date';

const WIDGETS_ENDPOINT = '/reporting';

const SET_CARD_VOLUME_STAT = 'widgets/SET_CARD_VOLUME_STAT';
const SET_CHARGEBACKS_STAT = 'widgets/SET_CHARGEBACKS_STAT';
const SET_REFUNDS_STAT = 'widgets/SET_REFUNDS_STAT';
const SET_DASHBOARD_DATE_PICKER_VALUE = 'widgets/SET_DASHBOARD_DATE_PICKER_VALUE';
const SET_DASHBOARD_ACCOUNTS_VALUE = 'widgets/SET_DASHBOARD_ACCOUNTS_VALUE';
const SET_DASHBOARD_PRODUCT_VALUE = 'widgets/SET_DASHBOARD_PRODUCT_VALUE';
const SET_DASHBOARD_PROCESSOR_VALUE = 'widgets/SET_DASHBOARD_PROCESSOR_VALUE';

const state = {
  cardVolumeStat: '',
  chargebacksStat: '',
  refundsStat: '',
  dashboardDatePickerValue: '',
  dashboardAccountsValue: {},
  dashboardProductValue: {},
  dashboardProcessorValue: {}
};

// actions
const actions = {
  async WIDGETS_CARD_VOLUME_STAT({ commit }, data) {
    const endpoint = `${WIDGETS_ENDPOINT}/transactions/totals/cc`;
    const params = formatDateSelectors(data);
    if (data.subMerchantId?.length) {
      params.subMerchantId = data.subMerchantId.join(',');
    }

    try {
      const response = await axios.get(endpoint, { params });
      commit(SET_CARD_VOLUME_STAT, response.data);
    } catch (error) {
      commit(SET_CARD_VOLUME_STAT, null);
      throw new Error('WIDGETS_CARD_VOLUME_STAT_ERROR_MESSAGE');
    }
  },

  async WIDGETS_CHARGEBACKS_STAT({ commit }, data) {
    const endpoint = `${WIDGETS_ENDPOINT}/disputes`;
    const params = formatDateSelectors(data);
    if (data.subMerchantId?.length) params.submerchantId = data.subMerchantId.join(',');
    delete params.subMerchantId;

    try {
      const response = await axios.get(endpoint, { params });
      const { data: responseData } = response;
      let totalDisputedAmount = 0;
      if (responseData.data) {
        responseData.data.forEach(dispute => {
          totalDisputedAmount += dispute.disputeAmount;
        });
      }
      responseData.total = totalDisputedAmount;
      commit(SET_CHARGEBACKS_STAT, responseData);
    } catch (error) {
      commit(SET_CHARGEBACKS_STAT, null);
      throw new Error('WIDGETS_CHARGEBACKS_ERROR_MESSAGE');
    }
  },

  async WIDGETS_REFUNDS_STAT({ commit }, data) {
    const endpoint = `${WIDGETS_ENDPOINT}/transactions/totals/refunds`;
    const params = formatDateSelectors(data);
    if (data.subMerchantId?.length) {
      params.subMerchantId = data.subMerchantId.join(',');
    }
    try {
      const response = await axios.get(endpoint, { params });
      commit(SET_REFUNDS_STAT, response.data);
    } catch (error) {
      commit(SET_REFUNDS_STAT, null);
      throw new Error('WIDGETS_REFUNDS_ERROR_MESSAGE');
    }
  },
  WIDGETS_SET_DASHBOARD_DATE_PICKER_VALUE({ commit }, data) {
    commit(SET_DASHBOARD_DATE_PICKER_VALUE, data);
  },
  WIDGETS_CLEAR_DASHBOARD_DATE_PICKER_VALUE({ commit }) {
    commit(SET_DASHBOARD_DATE_PICKER_VALUE, '');
  },
  WIDGETS_SET_DASHBOARD_ACCOUNTS_VALUE({ commit }, data) {
    commit(SET_DASHBOARD_ACCOUNTS_VALUE, data);
  },
  WIDGETS_SET_DASHBOARD_PRODUCT_VALUE({ commit }, data) {
    commit(SET_DASHBOARD_PRODUCT_VALUE, data);
  },
  WIDGETS_SET_DASHBOARD_PROCESSOR_VALUE({ commit }, data) {
    commit(SET_DASHBOARD_PROCESSOR_VALUE, data);
  },
  WIDGETS_CLEAR_DASHBOARD_ACCOUNTS_VALUE({ commit }) {
    commit(SET_DASHBOARD_ACCOUNTS_VALUE, '');
  },
  WIDGETS_CLEAR_DASHBOARD_PRODUCT_VALUE({ commit }) {
    commit(SET_DASHBOARD_PRODUCT_VALUE, '');
  },
  WIDGETS_CLEAR_DASHBOARD_PROCESSOR_VALUE({ commit }) {
    commit(SET_DASHBOARD_PROCESSOR_VALUE, '');
  }
};

// getters
const getters = {
  getCardVolumeStat: state => state.cardVolumeStat,
  getChargebacksStat: state => state.chargebacksStat,
  getRefundsStat: state => state.refundsStat,
  getDashboardDatePickerValue: state => state.dashboardDatePickerValue,
  getDashboardAccountsValue: state => state.dashboardAccountsValue,
  getDashboardProductValue: state => state.dashboardProductValue,
  getDashboardProcessorValue: state => state.dashboardProcessorValue
};

// mutations
const mutations = {
  [SET_CARD_VOLUME_STAT](state, data) {
    state.cardVolumeStat = data;
  },
  [SET_CHARGEBACKS_STAT](state, data) {
    state.chargebacksStat = data;
  },
  [SET_REFUNDS_STAT](state, data) {
    state.refundsStat = data;
  },
  [SET_DASHBOARD_DATE_PICKER_VALUE](state, data) {
    state.dashboardDatePickerValue = data;
  },
  [SET_DASHBOARD_ACCOUNTS_VALUE](state, data) {
    state.dashboardAccountsValue = data;
  },
  [SET_DASHBOARD_PRODUCT_VALUE](state, data) {
    state.dashboardProductValue = data;
  },
  [SET_DASHBOARD_PROCESSOR_VALUE](state, data) {
    state.dashboardProcessorValue = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};