import Vue from 'vue';
import Vuex from 'vuex';
// Modules
import config from '@/config';
import { ENVIRONMENTS } from '@commons/data/constants';
import submerchants from './submerchants';

Vue.use(Vuex);

const debug = config.ENV !== ENVIRONMENTS.PROD;

export default {
  namespaced: true,
  modules: { submerchants },
  strict: debug
};
