import { formatDateSelectors } from '@commons/helpers/date';
import axios from 'axios';
import qs from 'qs';

const PLANS_ENDPOINT_QUERY = `subscriptions/plans`;
const SUBSCRIPTIONS_ENDPOINT_QUERY = `subscriptions`;
const SUBSCRIPTIONS_ENDPOINT = `subscriptions`;

const TEMP_PLANS_ENDPOINT = `backoffice/temps`;
const TEMP_PLAN_ENDPOINT = `${TEMP_PLANS_ENDPOINT}/:tempId`;

const SET_PLANS = 'plans/SET_PLANS';
const SET_SUBSCRIPTIONS = 'subscriptions/SET_SUBSCRIPTIONS';
const REMOVE_PLAN = 'plans/REMOVE_PLAN';
// initial state
const state = { plans: {}, subscriptions: {} };

const actions = {
  CLEAR_PLANS({ commit }) {
    commit(SET_PLANS, {});
  },
  async GET_PLANS({ commit }, params) {
    try {
      const response = await axios.get(PLANS_ENDPOINT_QUERY, {
        params,
        paramsSerializer: params =>
          qs.stringify(params, { indices: false, allowDots: false })
      });
      let responseData = [];
      if (response.data.data) {
        response.data.data.forEach((plan, index) => {
          responseData[index] = { ...plan };
        });
      }
      commit(SET_PLANS, { count: response.data.count, data: responseData });
      return { count: response.data.count, data: responseData };
    } catch (error) {
      console.error(error);
      error => reject(error);
    }
  },
  async GET_PLAN_BY_ID({ commit }, params) {
    try {
      const response = await axios.get(`${PLANS_ENDPOINT_QUERY}/${params.id}`);
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      error => reject(error);
    }
  },
  async ADD_PLANS(context, data) {
    try {
      const response = await axios.post(`${SUBSCRIPTIONS_ENDPOINT}/plan/create`, data);
      return response.data;
    } catch (error) {
      console.log({ error });
      throw error.response.data.message || 'Error while trying to add a new plan.';
    }
  },
  async ADD_TEMP_PLAN(context, data) {
    const tempPlan = {
      subMerchantId: data.subMerchantId,
      name: data.name,
      description: data.description,
      title: data.title,
      subTitle: data.subTitle,
      schedules: data.schedules,
      status: data.status || 'draft',
      cancelOptions: data.cancelOptions || []
    };
    try {
      const response = await axios.post(TEMP_PLANS_ENDPOINT, {
        modelName: 'plans',
        modelId: data.planId,
        data: tempPlan
      });
      return response.data;
    } catch (e) {
      if (e.response?.data?.message) throw e.response.data.message;
      else throw new Error('Error creating Temp Plan');
    }
  },
  async REMOVE_PLAN({ commit }, data) {
    try {
      const response = await axios.post(`${SUBSCRIPTIONS_ENDPOINT}/plan/delete/`, data);
      commit(REMOVE_PLAN, data.planId);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  DELETE_PLAN_FROM_LIST({ commit, state }, planId) {
    let plans = JSON.parse(JSON.stringify(state.plans));
    const planIndex = plans.data.findIndex(c => c.planId === planId);
    if (planIndex > -1) {
      plans.data.splice(planIndex, 1);
      commit(SET_PLANS, plans);
    }
  },
  async EDIT_PLANS(context, data) {
    try {
      let response = await axios.put(
        `${SUBSCRIPTIONS_ENDPOINT}/plan/update/${data.planId}`,
        data
      );
      return response.data;
    } catch (error) {
      return 'Error while editing the plan. Try again later.';
    }
  },
  async EDIT_TEMP_PLAN(context, data) {
    const { tempId } = data;
    const tempPlan = {
      subMerchantId: data.subMerchantId,
      name: data.name,
      description: data.description,
      title: data.title,
      subTitle: data.subTitle,
      schedules: data.schedules,
      cancelOptions: data.cancelOptions || []
    };
    if (data.status) {
      tempPlan.status = data.status;
    }
    try {
      const response = await axios.put(
        TEMP_PLAN_ENDPOINT.replace(':tempId', tempId),
        tempPlan
      );
      return response.data;
    } catch (e) {
      throw new Error('Error while editing the Temp Plan. Try again later.');
    }
  },
  async ACTIVATE_PLAN({ commit, state }, data) {
    try {
      let response = await axios.put(
        `${SUBSCRIPTIONS_ENDPOINT}/plan/activate/${data.planId}`,
        data
      );
      return response.data;
    } catch (error) {
      return 'Error activating plan. Try again later.';
    }
  },
  async DEACTIVATE_PLAN({ commit, state }, data) {
    try {
      let response = await axios.put(
        `${SUBSCRIPTIONS_ENDPOINT}/plan/deactivate/${data.planId}`,
        data
      );
      return response.data;
    } catch (error) {
      return 'Error deactivating plan. Try again later.';
    }
  },
  ADD_PLAN_TO_LIST({ commit, state }, plan) {
    let plans = JSON.parse(JSON.stringify(state.plans));
    if (plans.data) {
      const elementIndex = plans.data.findIndex(c => c._id === plan._id);

      const planToAdd = {
        ...plan
      };
      if (elementIndex === -1) {
        plans.data.unshift(planToAdd);
        plans.count++;
      } else plans.data[elementIndex] = planToAdd;
      commit(SET_PLANS, plans);
    }
  },
  async DOWNLOAD_PLANS(context, params) {
    try {
      const response = await axios.get(PLANS_ENDPOINT_QUERY, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/csv'
        },
        params: { exportType: 'csv', ...params },
        paramsSerializer: params => qs.stringify(params, { allowDots: true })
      });
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'octet/stream' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'plans.csv');
      document.body.appendChild(link);
      link.click();
      resolve();
    } catch (error) {
      return error;
    }
  },
  async DOWNLOAD_SUBSCRIPTIONS(context, params) {
    try {
      let response = await axios.get(
        SUBSCRIPTIONS_ENDPOINT_QUERY,
        {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/csv'
          },
          params: { exportType: 'csv', ...params },
          paramsSerializer: params => qs.stringify(params, { allowDots: true })
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'octet/stream' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'subscriptions.csv');
      document.body.appendChild(link);
      link.click();
      resolve();
    } catch (error) {
      return error;
    }
  },
  async ADD_SUBSCRIPTIONS(context, data) {
    try {
      let response = await axios.post(`${SUBSCRIPTIONS_ENDPOINT}/create`, data);
      return response.data;
    } catch (error) {
      return 'Error while creating the subscriptions. Try again later.';
    }
  },
  async EDIT_SUBSCRIPTIONS(context, data) {
    try {
      let response = await axios.put(
        `${SUBSCRIPTIONS_ENDPOINT}/update/${data.subscriptionId}`,
        data
      );
      return response.data;
    } catch (error) {
      return 'Error while editing the subscription. Try again later.';
    }
  },
  async DEACTIVATE_SUBSCRIPTION({ commit, state }, data) {
    try {
      let response = await axios.put(
        `${SUBSCRIPTIONS_ENDPOINT}/deactivate/${data.subscriptionId}`,
        data
      );
      return response.data;
    } catch (error) {
      return 'Error deactivating subscription. Try again later.';
    }
  },
  async ACTIVATE_SUBSCRIPTION({ commit, state }, data) {
    try {
      let response = await axios.put(
        `${SUBSCRIPTIONS_ENDPOINT}/activate/${data.subscriptionId}`,
        data
      );
      return response.data;
    } catch (error) {
      return 'Error activating subscription. Try again later.';
    }
  },
  async DELETE_SUBSCRIPTION({ commit, state }, data) {
    try {
      let response = await axios.delete(
        `${SUBSCRIPTIONS_ENDPOINT}/delete/${data.subscriptionId}`,
        data
      );
      return response.data;
    } catch (error) {
      return 'Error deleted subscription. Try again later.';
    }
  },
  ADD_SUBSCRIPTIONS_TO_LIST({ commit, state }, subscription) {
    let subscriptions = JSON.parse(JSON.stringify(state.subscriptions));
    if (!subscriptions) {
      subscriptions = {
        data: [],
        count: 0
      };
    }
    const elementIndex = subscriptions.data.findIndex(
      c => c.subscriptionId === subscription.subscriptionId
    );
    const subscriptionToAdd = {
      ...subscription
    };
    if (elementIndex === -1) {
      subscriptions.data.unshift(subscriptionToAdd);
      subscriptions.count++;
    } else {
      subscriptions.data.splice(elementIndex, 1, subscriptionToAdd);
    }
    commit(SET_SUBSCRIPTIONS, subscriptions);
  },
  DELETE_SUBSCRIPTIONS_FROM_LIST({ commit, state }, subscriptionId) {
    let subscriptions = JSON.parse(JSON.stringify(state.subscriptions));
    const subscriptionIndex = subscriptions.data.findIndex(
      c => c.subscriptionId === subscriptionId
    );
    if (subscriptionIndex > -1) {
      subscriptions.data.splice(subscriptionIndex, 1);
      commit(SET_SUBSCRIPTIONS, subscriptions);
    }
  },
  CLEAR_SUBSCRIPTIONS({ commit }) {
    commit(SET_SUBSCRIPTIONS, {});
  },
  async SEND_SUBSCRIPTION_INVITE({ commit }, params) {
    try {
      let response = await axios.post(`${SUBSCRIPTIONS_ENDPOINT}/plan/invite`, params);
      return response.data;
    } catch (error) {
      return 'Error sending invite. Try again later.';
    }
  },
  async GET_SUBSCRIPTIONS({ commit }, params) {
    try {
      params = formatDateSelectors(params);
      const response = await axios.get(SUBSCRIPTIONS_ENDPOINT_QUERY, {
        params,
        paramsSerializer: params =>
          qs.stringify(params, { indices: false, allowDots: false })
      });
      commit(SET_SUBSCRIPTIONS, { count: response.data.count, data: response.data.data });
      return { count: response.data.count, data: response.data.data };
    } catch (error) {
      return error;
    }
  }
};

const mutations = {
  [SET_PLANS](state, data) {
    state.plans = data;
  },
  [SET_SUBSCRIPTIONS](state, data) {
    state.subscriptions = data;
  },
  [REMOVE_PLAN](state, planId) {
    const planIndex = state.plans.data.findIndex(plan => plan._id === planId);
    if (planIndex > -1) {
      state.plans.data.splice(planIndex, 1);
    }
  }
};

const getters = {
  getPlans: state => state.plans,
  getSubscriptions: state => state.subscriptions
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
