var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.inlineLabel ? 'd-flex flex-column' : ''],staticStyle:{"margin-bottom":"1rem"}},[_c('div',{staticClass:"main-div",class:{
      'input-group-focus': _vm.focused,
      'has-label': _vm.label,
      'inline-label': _vm.inlineLabel
    }},[_vm._t("label",function(){return [(_vm.label)?_c('label',{staticClass:"input-label",style:(_vm.labelStyle)},[_c('span',[_vm._v(_vm._s(_vm.label))]),(_vm.required)?_c('i',{class:`required-field align-self-center float-${_vm.requiredDotPosition}`}):_vm._e()]):_vm._e()]}),_c('div',{staticClass:"input-wrapper mb-0",class:{
        'input-group': _vm.hasIcon
      }},[_vm._t("addonLeft",function(){return [(_vm.addonLeftIcon)?_c('span',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text",class:{
              'error-brdr': _vm.error,
              'success-brdr': !_vm.error && _vm.touched
            }},[_c('i',{class:_vm.addonLeftIcon})])]):_vm._e()]}),_vm._t("default",function(){return [_c('Select',_vm._g({directives:[{name:"focus",rawName:"v-focus",value:(_vm.focus),expression:"focus"}],ref:"select",staticClass:"select-primary",class:{
            'error-border': _vm.error,
            'success-border': _vm.hasValidation && !_vm.error && _vm.touched,
            'soft-disable': _vm.readOnly
          },attrs:{"filterable":"","name":_vm.name,"value":_vm.value,"placeholder":_vm.placeholder,"disabled":_vm.disabled || _vm.readOnly,"popperClass":_vm.popperClass,"filter-method":_vm.filterMethod,"multiple":_vm.multiple,"multiple-limit":_vm.multipleLimit,"allow-create":_vm.allowCreate,"default-first-option":_vm.allowCreate,"collapse-tags":_vm.collapseTags,"loading":_vm.loading,"remote":_vm.remote,"remote-method":_vm.executeRemoteMethod,"no-data-text":'Please type a value and press enter'},nativeOn:{"keypress":function($event){return _vm.onKeypress.apply(null, arguments)}}},_vm.listeners),[_vm._t("options",function(){return _vm._l((_vm.options),function(option,index){return _c('Option',{key:option.value + index,staticClass:"select-primary",attrs:{"label":option.label,"value":option.value,"disabled":option.disabled}})})})],2)]}),(_vm.hasValidation && !_vm.error && _vm.touched)?_c('span',{staticClass:"success-icon d-none d-lg-block"},[_c('i',{staticClass:"fal fa-check-circle",attrs:{"aria-hidden":"true"}})]):(_vm.error)?_c('span',{staticClass:"error-icon"},[_c('i',{staticClass:"fal fa-exclamation-triangle",attrs:{"aria-hidden":"true"}})]):_vm._e()],2)],2),((_vm.error || _vm.$slots.error) && _vm.showErrorMsg)?_vm._t("error",function(){return [_c('i',{staticClass:"error-msg",class:[_vm.inlineLabel ? 'align-self-end' : '']},[_vm._v(_vm._s(_vm.error))])]}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }