import axios from 'axios';

// Endpoints
const PRODUCTS_ENDPOINT = 'backoffice/spproducts';
const SP_MCC_ENDPOINT = `${PRODUCTS_ENDPOINT}/:spProductSlug/mcc`;
const SP_FEES = `${PRODUCTS_ENDPOINT}/:spProductSlug/fees`;
const SP_OWNERSHIP_TYPES_ENDPOINT = `${PRODUCTS_ENDPOINT}/:spProductSlug/ownership-types`;

// actions
const actions = {
  async GET_SP_MCC_CODES(context, spProductSlug) {
    try {
      const response = await axios.get(
        SP_MCC_ENDPOINT.replace(':spProductSlug', spProductSlug)
      );
      return response.data;
    } catch (error) {
      throw new Error('Error while getting the Product MCC Codes. Try again later.');
    }
  },
  async GET_SP_OWNERSHIP_TYPES(context, { spProductSlug, merchantType }) {
    try {
      const response = await axios.get(
        SP_OWNERSHIP_TYPES_ENDPOINT.replace(':spProductSlug', spProductSlug),
        { params: { merchantType } }
      );
      return response.data;
    } catch (error) {
      throw new Error(
        'Error while getting the Product Ownership Types. Try again later.'
      );
    }
  },
  async GET_SP_FEES(context, { spProductSlug }) {
    try {
      const response = await axios.get(SP_FEES.replace(':spProductSlug', spProductSlug));
      return response.data;
    } catch (error) {
      throw new Error('Error while getting the Product Fees. Try again later.');
    }
  }
};

export default { actions };
