// Convert HEX to RGB with memoization and cache size limit
function hexToRgb(hex) {
  const normalizedHex = normalizeHex(hex);
  if (!hexToRgb.cache) {
    hexToRgb.cache = new Map();
  }
  if (hexToRgb.cache.has(normalizedHex)) {
    const value = hexToRgb.cache.get(normalizedHex);
    // Move the key to the end to mark it as recently used
    hexToRgb.cache.delete(normalizedHex);
    hexToRgb.cache.set(normalizedHex, value);
    return value ? { ...value } : null; // Return a shallow copy
  }

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(normalizedHex);
  const rgb = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;

  hexToRgb.cache.set(normalizedHex, rgb);
  manageCache(hexToRgb.cache);
  return rgb ? { ...rgb } : null; // Return a shallow copy
}

// Convert RGB to HSL with memoization and cache size limit
function rgbToHsl(rgb) {
  if (!rgbToHsl.cache) {
    rgbToHsl.cache = new Map();
  }
  const key = `${rgb.r},${rgb.g},${rgb.b}`;
  if (rgbToHsl.cache.has(key)) {
    const value = rgbToHsl.cache.get(key);
    // Move the key to the end to mark it as recently used
    rgbToHsl.cache.delete(key);
    rgbToHsl.cache.set(key, value);
    return { ...value }; // Return a shallow copy
  }

  // Clone r, g, b to prevent mutation
  const r = rgb.r / 255;
  const g = rgb.g / 255;
  const b = rgb.b / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0;
  let s = 0;
  const l = (max + min) / 2;

  if (max !== min) {
    const delta = max - min;
    s = l > 0.5 ? delta / (2 - max - min) : delta / (max + min);
    switch (max) {
      case r:
        h = (g - b) / delta + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / delta + 2;
        break;
      case b:
        h = (r - g) / delta + 4;
        break;
    }
    h = Math.round(h * 60);
  }

  const hsl = { h, s, l };
  rgbToHsl.cache.set(key, hsl);
  manageCache(rgbToHsl.cache);
  return hsl;
}

// Convert HSL to RGB with memoization and cache size limit
function hslToRgb(hsl) {
  if (!hslToRgb.cache) {
    hslToRgb.cache = new Map();
  }
  const key = `${hsl.h},${hsl.s},${hsl.l}`;
  if (hslToRgb.cache.has(key)) {
    const value = hslToRgb.cache.get(key);
    // Move the key to the end to mark it as recently used
    hslToRgb.cache.delete(key);
    hslToRgb.cache.set(key, value);
    return { ...value }; // Return a shallow copy
  }

  // Clone h, s, l to prevent mutation
  const h = hsl.h / 360;
  const { s, l } = hsl;

  let r = 0;
  let g = 0;
  let b = 0;

  const hue2rgb = (p, q, t) => {
    if (t < 0) t += 1;
    if (t > 1) t--;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  };

  if (s === 0) {
    r = g = b = l; // Achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  const rgb = {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255)
  };
  hslToRgb.cache.set(key, rgb);
  manageCache(hslToRgb.cache);
  return rgb;
}

// Convert RGB to HEX with memoization and cache size limit
function rgbToHex({ r, g, b }) {
  const key = `${r},${g},${b}`;
  if (!rgbToHex.cache) {
    rgbToHex.cache = new Map();
  }
  if (rgbToHex.cache.has(key)) {
    const value = rgbToHex.cache.get(key);
    // Move the key to the end to mark it as recently used
    rgbToHex.cache.delete(key);
    rgbToHex.cache.set(key, value);
    return value;
  }

  const hex = `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  const normalizedHex = normalizeHex(hex); // Normalize to lowercase
  rgbToHex.cache.set(key, normalizedHex);
  manageCache(rgbToHex.cache);
  return normalizedHex;
}

// Calculate relative luminance of a HEX color with memoization and cache size limit
function getLuminance(hex) {
  const normalizedHex = normalizeHex(hex);
  if (!getLuminance.cache) {
    getLuminance.cache = new Map();
  }
  if (getLuminance.cache.has(normalizedHex)) {
    const value = getLuminance.cache.get(normalizedHex);
    // Move the key to the end to mark it as recently used
    getLuminance.cache.delete(normalizedHex);
    getLuminance.cache.set(normalizedHex, value);
    return value;
  }

  const rgb = hexToRgb(normalizedHex);
  if (!rgb) return 0;
  const a = [rgb.r, rgb.g, rgb.b].map(v => {
    const normalized = v / 255;
    return normalized <= 0.03928 ? normalized / 12.92 : ((normalized + 0.055) / 1.055) ** 2.4;
  });
  const luminance = 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
  getLuminance.cache.set(normalizedHex, luminance);
  manageCache(getLuminance.cache);
  return luminance;
}

// Calculate contrast ratio between two HEX colors with memoization and cache size limit
function getContrastRatio(hex1, hex2) {
  const normalizedHex1 = normalizeHex(hex1);
  const normalizedHex2 = normalizeHex(hex2);
  const cacheKey = `${normalizedHex1}-${normalizedHex2}`;

  if (!getContrastRatio.cache) {
    getContrastRatio.cache = new Map();
  }
  if (getContrastRatio.cache.has(cacheKey)) {
    const value = getContrastRatio.cache.get(cacheKey);
    // Move the key to the end to mark it as recently used
    getContrastRatio.cache.delete(cacheKey);
    getContrastRatio.cache.set(cacheKey, value);
    return value;
  }

  const lum1 = getLuminance(normalizedHex1);
  const lum2 = getLuminance(normalizedHex2);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);

  const ratio = (brightest + 0.05) / (darkest + 0.05);
  getContrastRatio.cache.set(cacheKey, ratio);
  manageCache(getContrastRatio.cache);
  return ratio;
}

// Determine appropriate text color based on background for sufficient contrast
function determineTextColor(backgroundHex) {
  const whiteContrast = getContrastRatio(backgroundHex, '#ffffff');
  return whiteContrast > 2 ? '#ffffff' : '#000000';
}

// Check if two colors have sufficient contrast
function hasSufficientContrast(bgColor, fgColor) {
  return getContrastRatio(bgColor, fgColor) >= 4;
}

// Adjust foreground color to meet contrast requirements
export function adjustColorForTextContrast(bgColor, preferredColor = '#ffffff') {
  // Removed console.log statements for production performance

  const hasSufficient = hasSufficientContrast(bgColor, preferredColor);

  return hasSufficient ? preferredColor : determineTextColor(bgColor);
}

// Smartly adjust color based on its characteristics without mutating the original color
function smartAdjustColor(hex, options = {}) {
  const { lightness = 0, saturation = 0, hue = 0, contrast = 1.5, overDark } = options;

  // Clone the hex to prevent external mutations
  const normalizedHex = normalizeHex(hex);
  const rgb = hexToRgb(normalizedHex);
  if (!rgb) {
    console.warn(`Invalid hex color: ${normalizedHex}`);
    return normalizedHex;
  }

  // Clone the HSL object to avoid mutating the original
  const hsl = { ...rgbToHsl(rgb) };

  const MAX_LIGHTNESS = 0.95;
  const MIN_LIGHTNESS = 0.05;

  // Apply initial adjustments immutably
  const adjustedHsl = {
    l: Math.max(Math.min(hsl.l + lightness * 0.1, MAX_LIGHTNESS), MIN_LIGHTNESS),
    s: Math.max(Math.min(hsl.s + saturation * 0.1, 1), 0),
    h: (hsl.h + hue + 360) % 360
  };

  const adjustedRgb = hslToRgb(adjustedHsl);
  const adjustedHex = rgbToHex(adjustedRgb);

  // Calculate contrast between adjusted color and original
  const initialContrast = getContrastRatio(adjustedHex, normalizedHex);

  if (initialContrast < contrast || overDark !== undefined) {
    const bgLuminance = overDark !== undefined ? getLuminance(overDark ? '#000000' : '#FFFFFF') : undefined;
    const originalLuminance = getLuminance(normalizedHex);
    const fgLuminance = getLuminance(adjustedHex);

    // Determine if we should lighten or darken
    const shouldLighten = bgLuminance !== undefined
      ? (bgLuminance + originalLuminance) / 2 < fgLuminance
      : originalLuminance < fgLuminance;

    // Calculate the target luminance based on desired contrast
    let targetLuminance;
    if (bgLuminance === undefined) {
      targetLuminance = shouldLighten
        ? (originalLuminance + 0.05) * contrast - 0.05
        : (originalLuminance + 0.05) / contrast - 0.05;
    } else {
      targetLuminance = shouldLighten
        ? Math.max((bgLuminance + 0.05) * contrast - 0.05, (originalLuminance + 0.05) * contrast - 0.05)
        : Math.min((bgLuminance + 0.05) / contrast - 0.05, (originalLuminance + 0.05) / contrast - 0.05);
    }

    // Convert target luminance to lightness
    const targetLightness = convertLuminanceToLightness(targetLuminance);

    // Adjust lightness immutably
    const finalHsl = {
      ...adjustedHsl,
      l: Math.max(Math.min(targetLightness, MAX_LIGHTNESS), MIN_LIGHTNESS)
    };

    const finalRgb = hslToRgb(finalHsl);
    return rgbToHex(finalRgb);
  }

  return adjustedHex;
}

// Adjust saturation while adhering to greyscale and saturation constraints
function setAbsoluteColor(hex, { lightness, saturation, hue }) {
  const rgb = hexToRgb(hex);
  if (!rgb) {
    console.warn(`Invalid hex color: ${hex}`);
    return hex;
  }

  const hsl = rgbToHsl(rgb);

  // Determine if the original color is greyscale
  const isGreyscale = hsl.s < 0.05;

  if (lightness !== undefined) {
    // Use the provided lightness directly
    hsl.l = lightness;
  }

  if (saturation !== undefined) {
    hsl.s = isGreyscale ? 0 : saturation;
  }

  if (hue !== undefined) {
    hsl.h = (hsl.h + hue) % 360;
  }

  const adjustedRgb = hslToRgb(hsl);
  return rgbToHex(adjustedRgb);
}

// Generate sidebar gradient based on theme color
function generateSidebarGradient(color) {
  const rgb = hexToRgb(color);
  if (!rgb) return color;

  const endColor = smartAdjustColor(color, { lightness: 0.1, saturation: -0.1, hue: 10 });

  return `linear-gradient(0deg, ${color}, ${endColor})`;
}

// Helper to get color based on theme name
function getThemeColor(theme) {
  const themeColors = {
    vue: '#42b883',
    blue: '#5e72e4',
    green: '#2dce89',
    orange: '#fb6340',
    red: '#f5365c',
    'higher-purple': '#dd4ee8',
    'light-black': '#282a3c',
    'light-gray': '#e3e3e3',
    'medium-gray': '#dddddd',
    'dark-gray': '#9a9a9a',
    white: '#ffffff'
  };

  // Check if theme is already a color
  const isColor = /^#[0-9A-F]{6}$/i.test(theme);
  if (isColor) {
    return theme;
  }

  return themeColors[theme] || '#ffffff';
}

// Calculate and cache clip colors to avoid redundant computations
function calculateClipColor(bgColor, fgColor, targetContrast) {
  const cacheKey = `${normalizeHex(bgColor)}-${normalizeHex(fgColor)}-${targetContrast}`;
  if (!calculateClipColor.cache) {
    calculateClipColor.cache = new Map();
  }
  if (calculateClipColor.cache.has(cacheKey)) {
    const value = calculateClipColor.cache.get(cacheKey);
    // Move the key to the end to mark it as recently used
    calculateClipColor.cache.delete(cacheKey);
    calculateClipColor.cache.set(cacheKey, value);
    return value;
  }

  const bgLuminance = getLuminance(bgColor);
  const fgLuminance = getLuminance(fgColor);
  const brightest = Math.max(bgLuminance, fgLuminance);
  const darkest = Math.min(bgLuminance, fgLuminance);

  const ratio = (brightest + 0.05) / (darkest + 0.05);

  let adjustedColor = fgColor;

  if (ratio < targetContrast) {
    const shouldDarken = fgLuminance > bgLuminance;
    const rgb = hexToRgb(fgColor);
    const hsl = rgbToHsl(rgb);

    // Calculate the required luminance for the foreground color
    const requiredLuminance = shouldDarken
      ? (bgLuminance + 0.05) / targetContrast - 0.05
      : targetContrast * (bgLuminance + 0.05) - 0.05;

    // Convert the required luminance back to lightness in HSL
    const requiredLightness = convertLuminanceToLightness(requiredLuminance);

    // Clamp the lightness value between 0.2 and 0.8
    hsl.l = Math.max(0.2, Math.min(0.8, requiredLightness));

    const adjustedRgb = hslToRgb(hsl);
    adjustedColor = rgbToHex(adjustedRgb);
  }

  calculateClipColor.cache.set(cacheKey, adjustedColor);
  manageCache(calculateClipColor.cache);
  return adjustedColor;
}

// Helper function to convert luminance to lightness
function convertLuminanceToLightness(luminance) {
  const lightness = luminance <= 0.008856 ? luminance * 903.3 : luminance ** (1 / 3) * 116 - 16;
  return Math.max(0, Math.min(lightness / 100, 1));
}

// Generate color variations with saturation constraints and optimized logic
function generateEnhancedColorVariations(color, darkMode) {
  const originalRgb = hexToRgb(color);
  const originalHsl = rgbToHsl(originalRgb);
  const isOriginalGreyscale = originalHsl.s < 0.05;

  for (let lightnessIndex = 1; lightnessIndex < 10; lightnessIndex++) {
    const lightness = lightnessIndex * 0.1; // 10% - 90%
    for (let saturationIndex = 0; saturationIndex <= 20; saturationIndex++) {
      const saturation = isOriginalGreyscale ? 0 : saturationIndex * 0.05; // 0% - 100%
      const variableName = `--theme-primary-color-lightness-${lightnessIndex}-saturation-${saturationIndex}`;

      const adjustedColor = setAbsoluteColor(color, {
        lightness,
        saturation
      });

      const finalColor = darkMode
        ? smartAdjustColor(adjustedColor, { lightness: 0.2, saturation: 0.1 })
        : adjustedColor;

      document.documentElement.style.setProperty(variableName, finalColor);
    }
  }
}

// Batch setting CSS variables and optimized DOM manipulation
export function setUiThemeColors(theme, darkMode = false) {
  let color = getThemeColor(theme);

  if (darkMode) {
    color = smartAdjustColor(color, { lightness: -0.3, saturation: 0.2 });
  }

  const styleProps = {
    '--theme-primary-color': color,
    '--theme-primary-color-lighter': smartAdjustColor(color, {
      lightness: 0.15,
      saturation: -0.08,
      contrast: 2.5,
      overDark: darkMode
    }),
    '--theme-primary-color-darker': smartAdjustColor(color, {
      lightness: -0.15,
      saturation: 0.08,
      contrast: 4.5
    }),
    '--theme-primary-color-contrast': adjustColorForTextContrast(color, '#ffffff'),
    '--theme-primary-color-contrast-opposite': adjustColorForTextContrast(
      color,
      '#000000'
    )
    // '--theme-sidebar-gradient': generateSidebarGradient(color)
  };

  // Batch setting CSS variables
  Object.entries(styleProps).forEach(([key, value]) => {
    document.documentElement.style.setProperty(key, value);
  });

  // Generate color variations
  generateEnhancedColorVariations(color, darkMode);

  // Set data attribute for backward compatibility
  const sidebar = document.querySelector('.sidebar');
  if (sidebar) {
    sidebar.setAttribute('data', theme);
  }
}

// Set UI theme colors based on HEX value with optimized functionality
export function setUiThemeColorsWithHex(color) {
  document.documentElement.style.setProperty('--facilitator-theme-color', color);

  const bgColor = setAbsoluteColor(color, { lightness: 0.35, saturation: 0.75, hue: 5 });
  document.documentElement.style.setProperty('--facilitator-theme-bg-color', bgColor);

  const lighterColor = setAbsoluteColor(color, {
    lightness: 0.58,
    saturation: 0.9,
    hue: 8
  });
  const darkerColor = setAbsoluteColor(color, {
    lightness: 0.2,
    saturation: 0.1,
    hue: -10
  });
  document.documentElement.style.setProperty(
    '--facilitator-theme-color-lighter',
    lighterColor
  );
  document.documentElement.style.setProperty(
    '--facilitator-theme-color-darker',
    darkerColor
  );

  const textColor = adjustColorForTextContrast(bgColor);
  document.documentElement.style.setProperty('--facilitator-text-color', textColor);
}

// Helper function to normalize HEX strings
function normalizeHex(hex) {
  return hex.toLowerCase();
}

// Helper function to manage cache size
const MAX_CACHE_SIZE = 500;
function manageCache(cache) {
  if (cache.size > MAX_CACHE_SIZE) {
    // Remove the first (oldest) key inserted
    const firstKey = cache.keys().next().value;
    cache.delete(firstKey);
  }
}